import React from "react";
import { Card, Col, Image, Table, Row, Badge, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import undraw_profile from "../../../src/assets/images/undraw_profile.svg";
import CardHeader from "../Components/CardHeader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { DashCard } from "../Dashboard/Content/DashCard";
import { faChartSimple, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import { getDriverDetails } from "../services/users";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back",
    buttonUrl: "../drivers",
    icon: faArrowLeft,
  },
];

const DriverDetailView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    getDriverDetails(id).then((res) => setData(res.data));
  }, []);

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title=" Driver Details" />

      <Row>
        <Col lg={4} sm={12} xs={12} md={4}>
          <Card className="mb-3 border-0 shadow-sm rounded-2">
            <Card.Body>
              <Card.Img
                style={{ width: "100px", borderRadius: "10px" }}
                src={data.image ? data.image : undraw_profile}
                alt="Generic placeholder image"
                as={Image}
                fluid
              />

              <div className="col-12">
                <Card.Title className="mt-3 mb-2">
                  <strong>
                    {data.user &&
                      data.user.first_name + " " + data.user.last_name}
                  </strong>
                </Card.Title>
                <Card.Text>
                  <Badge bg="danger p-2  mb-4 shadow">
                    Manager :{" "}
                    {data.manager &&
                      data.manager.first_name + " " + data.manager.last_name}
                  </Badge>
                </Card.Text>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={8} sm={12} xs={12} md={8}>
          <Card className="mb-3 border-0 shadow-sm rounded-2 p-4">
            <Card.Body>
              <Table bordeless hover>
                <tbody>
                  <tr>
                    <td>
                      <b>Name</b>
                    </td>
                    <td colSpan={2}>
                      {data.user &&
                        data.user.first_name + " " + data.user.last_name}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Email</b>
                    </td>
                    <td colSpan={2}>{data.user && data.user.email}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Address</b>
                    </td>
                    <td colSpan={2}>{data.address}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Phone No</b>
                    </td>
                    <td colSpan={2}>{data.user && data.user.phone_number}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Truck</b>
                    </td>
                    <td colSpan={2}>{data.truck && data.truck.truck_name}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Plate Number</b>
                    </td>
                    <td colSpan={2}>{data.truck && data.truck.plate_number}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Trailer</b>
                    </td>
                    <td colSpan={2}>
                      {data.trailer && data.trailer.trailer_name}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Plate Number</b>
                    </td>
                    <td colSpan={2}>
                      {data.trailer && data.trailer.plate_number}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Status</b>
                    </td>
                    <td colSpan={2}>
                      {" "}
                      <Badge bg="success p-2  shadow">
                        {data.user && data.user.status === 1
                          ? "Active"
                          : "Non Active"}
                      </Badge>{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Row className="mt-5">
                <DashCard
                  value="0"
                  text=" ASSIGNED"
                  icon={faChartSimple}
                  cssStyle="bg-success border-0"
                />
                <DashCard
                  value="0"
                  text="COMPLETED"
                  icon={faUserPlus}
                  cssStyle="bg-warning border-0"
                />
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DriverDetailView;
