import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import CardHeader from "./../Components/CardHeader";
import { Table, Card, Col, Image, Badge, Button, Row } from "react-bootstrap";
import undraw_profile from "../../assets/images/undraw_profile.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "./tickects.css";
import CloseTicket from "../Components/CloseTicket";
import { useParams } from "react-router-dom";
import { getTicketById } from "../services/tickets";

const headerButtons = [
  {
    title: "TICKETS",
    buttonTitle: "Back to tickets",
    buttonUrl: "../tickets",
    icon: faArrowLeft,
  },
];
const getTruckTypeName = (type) => {
  if (type === "S") {
    return "Standard";
  }
  if (type === "M") {
    return "Mega";
  }
  if (type === "V") {
    return "Vario";
  }
};
const getStatusName = (status) => {
  if (status === "A") {
    return "Available";
  }
  if (status === "U") {
    return "Unavilable";
  }
  if (status === "H") {
    return "Holiday";
  }
};

const TicketDetials = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [reply, setReply] = useState(" ");
  const [closeData, setCloseData] = useState();

  const handleClose = (value) => {
    setShow(value);
  };

  useEffect(() => {
    getTicketById(id).then((res) => setData(res.data));
  }, []);

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title=" Ticket Details " />

      <Row>
        <Col sm={12} lg={5} xs={12}>
          <Card className="mb-3 p-4 border-0 shadow-sm">
            <Card.Body>
              <Card.Img
                style={{ width: "80px", borderRadius: "10px" }}
                src={undraw_profile}
                alt="Generic placeholder image"
                as={Image}
                fluid
              />
              <Card.Title className="mt-3 mb-2">
                Driver :
                <strong>
                  {data.driver &&
                    data.driver.user.first_name +
                      " " +
                      data.driver.user.last_name}
                </strong>
              </Card.Title>
              <Card.Text>
                <Badge bg="danger p-2  shadow">
                  Manager :{" "}
                  {data.manager &&
                    data.manager.first_name + " " + data.manager.last_name}
                </Badge>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card className="mb-3 mt-3 p-4 border-0 shadow-sm">
            <Card.Header className="text-start">
              <strong>Ticket information</strong>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3 ">
                <p className="text-start">{data.note}</p>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} lg={7} xs={12}>
          <Card className="mb-3 p-2 border-0 shadow-sm">
            <Card.Body>
              <Row className="mb-3">
                <Form.Group>
                  <Table striped bordered hover className="">
                    <tbody>
                      <tr>
                        <td className="fw-700">Truck</td>
                        <td>{data.driver && data.driver.truck.truck_name}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Platenumber</td>
                        <td>{data.driver && data.driver.truck.plate_number}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Type</td>
                        <td>
                          {data.driver &&
                            getTruckTypeName(data.driver.truck.truck_type)}
                        </td>
                      </tr>

                      <tr>
                        <td className="fw-700">Truck Department</td>
                        <td>
                          {data.driver && data.driver.truck.truck_department}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Weight</td>
                        <td>{data.driver && data.driver.truck.truck_weight}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Height</td>
                        <td>{data.driver && data.driver.truck.truck_height}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Status</td>
                        <td>{data.driver && data.driver.truck.status}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table striped bordered hover className="">
                    <tbody>
                      <tr>
                        <td className="fw-700">Trailer</td>
                        <td>
                          {data.driver && data.driver.trailer.trailer_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Platenumber</td>
                        <td>
                          {data.driver && data.driver.trailer.plate_number}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Type</td>
                        <td>
                          {data.driver &&
                            getTruckTypeName(data.driver.trailer.trailer_type)}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Working Load </td>
                        <td>
                          {data.driver && data.driver.trailer.working_load}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Owm Weight</td>
                        <td>{data.driver && data.driver.trailer.own_weight}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">No Of Axis</td>
                        <td>
                          {data.driver && data.driver.trailer.number_of_axis}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Max Weight authorized</td>
                        <td>{data.driver && data.driver.trailer.max_weight}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Length</td>
                        <td>
                          {data.driver && data.driver.trailer.trailer_length}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Width</td>
                        <td>
                          {data.driver && data.driver.trailer.trailer_width}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Height</td>
                        <td>
                          {data.driver && data.driver.trailer.trailer_height}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Status</td>
                        <td>
                          {data.driver &&
                            getStatusName(data.driver.trailer.status)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Form.Group>
              </Row>

              <Table bordeless="true" hover className="mt-4">
                <tbody>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Ticket title </b>
                    </td>
                    <td>{data.title}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Ticket type </b>
                    </td>
                    <td>{data.ticket_type && data.ticket_type.ticket_type}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Ticket created</b>
                    </td>
                    <td>{data.created_at}</td>
                  </tr>
      
                  {data.ticket_status === "closed" ? (
                    <>
                      {/* <tr className="text-start">
                        <td className="pl-3">
                          <b>Ticket closed at</b>
                        </td>
                        <td></td>
                      </tr> */}
                      <tr className="text-start">
                        <td className="pl-3" colSpan={2}>
                          <b>Ticket closed notes : {data.closed_note || "no Notes"}</b>
                        </td>
                      </tr>
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
              {/* <p className="text-start">
                Last Viewed By <b>29th March 2023 10:30 PM</b>
              </p> */}
             {data.ticket_status !== "closed" ? <Button
                onClick={() => setShow(true)}
                className="text-start w-100"
              >
                Close the ticket
              </Button>:""}
              {show ? (
                <CloseTicket
                  ticketId={id}
                  showMod={show}
                  onClose={handleClose}
                />
              ) : (
                <CloseTicket showMod={false} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col sm={12} lg={12} xs={12}>
          <Card className="mb-3 p-4 border-0 shadow-sm">
            <Card.Header className="text-start">
              <b>Messages</b>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Card className="mt-3  w-50 driver-message border-0 shadow-sm">
                  <Card.Body className="text-start ">
                    <p className="driver-fs-st">
                      This is response from driver.
                    </p>
                    <small>28/03/2023 10:30</small>
                  </Card.Body>
                </Card>
              </Row>
              <Row className=" mb-3">
                <Card className="mt-3 driver-card   w-50 manager-message  border-0 shadow-sm">
                  <Card.Body className="text-end">
                    <img
                      src={undraw_profile}
                      alt="Driver"
                      className="img-circle avatar"
                    />
                    <p className="driver-fs-st">
                      This is response from driver.
                    </p>
                    <small>28/03/2023 10:30</small>
                  </Card.Body>
                </Card>
              </Row>

              <Row className="mb-3">
                <Card className="mt-3 w-50 driver-message border-0 shadow-sm">
                  <Card.Body className="text-start ">
                    <p className="driver-fs-st">
                      This is response from driver.
                    </p>
                    <small>28/03/2023 10:30</small>
                  </Card.Body>
                </Card>
              </Row>

              <Row className="mb-3 mt-5">
                <hr />
                <Form.Group as={Col} className="p-0">
                  <Form.Control
                    as="textarea"
                    name="lastName"
                    placeholder="Add Reply"
                    value={reply}
                    onChange={(e) => setReply(e.target.value)}
                    style={{ height: "100px" }}
                  />
                </Form.Group>
              </Row>
              <Row className="mt-2">
                <Button
                  variant="primary"
                  type="submit"
                  className="shadow rounded-5"
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Reply to driver
                </Button>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default TicketDetials;
