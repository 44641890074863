import React,{useContext,useEffect} from "react";
import { Card } from "react-bootstrap";
import Table from "../../Components/Tables";
import CardHeader from "./../../Components/CardHeader";
import { faPlaneUp } from "@fortawesome/free-solid-svg-icons";
import TableActionButton from "../../Components/TableActionButton";
import { NavBarContext } from "../../context/NavBarContext";


const driverData = [
  {
    id: 1,
    name: "All",
    date: "29/10/2023 10:30"
  },
  {
    id: 2,
    name: "Europe Drivers",
    date: "29/10/2023 10:30"
  },
  {
    id: 3,
    name: "New Drivers",
    date: "29/10/2023 10:30"
  },
];

const headerButtons = [
  {
    buttonTitle: "Send Message",
    buttonUrl: "/dashboard/add/driversgroupmessage",
    icon: faPlaneUp,
  },
];

const DriverGroupMessage = () => {
  // const [tab,setTabs] = useContext(NavBarContext)
  // useEffect(() => {
  //   const data = [];
  //   for (let i = 0; i < localStorage.length; i++) {
  //     const key = localStorage.key(i);
  //     const keyv = `${key}`;
  //     const dataCur = localStorage.getItem(keyv);
  //     data[i] = JSON.parse(dataCur);
  //   }
  //   // const data = localStorage.getItem("tabs3");
  //   // const data1 = localStorage.getItem("tabs2");
  //   // const data2 = localStorage.getItem("tabs1");
  //   if (data === null || !data.includes("Groups Messages")) {
  //     const added= localStorage.setItem(
  //       "tabs4",
  //       JSON.stringify([{ title: "Groups Messages", url: "/dashboard/driversgroupmessage" }])
  //     );
  //     setTabs(added)
  //   }
  //   setTabs(data)
  // }, []);
  const columns = [
    {
      name: "No.",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Message title",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/driversgroupmessage/${row.id}`}
          UpdatePath={`/dashboard/update/driversgroupmessage/${row.id}`}
          deletePath={`driverDelete/${row.id}`}
        />
      ),
    },
  ];
  return (
      <div className="container-fluid">
        <CardHeader headerItems={headerButtons} title="Drivers Group Message" />
        <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
          <Card.Body>
            <div className="row">
              <Table columns={columns} row={driverData} />
            </div>
          </Card.Body>
        </Card>
      </div>
  );
};

export default DriverGroupMessage;

