import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./forms.css";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import CardHeader from "../../Components/CardHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";

import { getByIdTrucks, getTrucks } from "./../../services/trucks";
import { useEffect } from "react";
import { getTrailers, getTrailersByid } from "./../../services/trailer";
import { createDriver, getManager } from "../../services/users";

import RegisterValidater from "./../../Components/validators/RegisterValidater";
import { useNavigate } from "react-router-dom";
import { getjwt } from "../../services/authServices";
import { getUserType } from "../../services/usertypeApi";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../drivers", icon: faArrowLeft },
];

const driverValidator = (value) => {
  let errors = {};
  if (!value["address"]) {
    errors.address = "Address is required";
  }
  if (!value["truck"]) {
    errors.truck = "truck is required";
  }
  if (!value["trailer"]) {
    errors.trailer = "trailer is required";
  }
  if (!value["manager"]) {
    errors.manager = "manager is required";
  }
  return errors;
};
const USER_DATA = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  password: "",
  confirm_password: "",
  status: "",
  user_type: "",
};

const INITIAL_DATA = {
  address: "",
  truck: "",
  trailer: "",
  manager: "",
};
const DriverForm = () => {
  const navgate = useNavigate("");
  const [data, setData] = useState(INITIAL_DATA);
  const [user, setUser] = useState(USER_DATA);
  const [userType, setUserType] = useState([]);
  const [image, setImage] = useState("");
  const [truck, setTruck] = useState([]);
  const [truckDetials, setTruckDetials] = useState();
  const [trailer, setTrailer] = useState([]);
  const [trailerDetials, setTrailerDetials] = useState();
  const [manager, setManager] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...user,
      [name]: value,
    };
    setUser({ ...user, [name]: value });
    setFormErrors(RegisterValidater(inputs));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setFormErrors(driverValidator(inputs));
    if (inputs.truck) {
      getByIdTrucks(inputs.truck).then((res) => setTruckDetials(res.data));
    }
    if (inputs.trailer) {
      getTrailersByid(inputs.trailer).then((res) => setTrailerDetials(res.data));
    }
  };
  const driversTruck = () => {
    getTrucks().then((res) => setTruck(res.data));
  };
  const getMangerUser = () => {
    getManager().then((res) => setManager(res.data));
  };
  const driversTrailer = () => {
    getTrailers().then((res) => setTrailer(res.data));
  };

  useEffect(() => {
    driversTruck();
    driversTrailer();
    getMangerUser();
    getUserType().then((res) => setUserType(res.data));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    let form_data;
    if (
      user.first_name !== "" &&
      user.last_name !== "" &&
      user.phone_number !== "" &&
      user.email !== "" &&
      user.password !== "" &&
      user.status !== "" &&
      user.user_type !== "" &&
      data.address !== "" &&
      data.truck !== "" &&
      data.trailer !== "" &&
      data.manager !== ""
    ) {
      form_data = new FormData();
      user["token_data"] = JSON.parse(token);
      if (image) form_data.append("image", image);
      form_data.append("user", JSON.stringify(user));
      form_data.append("address", data.address);
      form_data.append("truck", data.truck);
      form_data.append("trailer", data.trailer);
      form_data.append("manager", data.manager);
      createDriver(form_data)
        .then((res) => {
          toastSuccess("succesfully created Drivers");
          navgate("../drivers");
        })
        .catch((err) => {
          let errObj = err.response.data;
          if (errObj.email) {
            toastError(errObj.email);
          }
          if (errObj.phone_number) {
            toastError(errObj.phone_number);
          }
          toastError(errObj);
        });
    } else {
      setFormErrors(RegisterValidater(user));
      setFormErrors(driverValidator(data));
    }
  };
  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Add Driver" />
      <Form noValidate className="needs-validation" onSubmit={handleSubmit}>
        <Row>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">First name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="First name"
                        onChange={handleUserChange}
                        value={user.first_name}
                        isInvalid={formErrors.first_name}
                      />
                      {formErrors.first_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.first_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Last name</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Last name"
                        onChange={handleUserChange}
                        value={user.last_name}
                        isInvalid={formErrors.last_name}
                      />
                      {formErrors.last_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.last_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter the Email Address"
                        onChange={handleUserChange}
                        value={user.email}
                        isInvalid={formErrors.email}
                      />
                      {formErrors.email && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Phone No:</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        placeholder="Enter the Phone Number"
                        onChange={handleUserChange}
                        value={user.phone_number}
                        isInvalid={formErrors.phone_number}
                      />
                      {formErrors.phone_number && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.phone_number}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        onChange={handleUserChange}
                        value={user.password}
                        isInvalid={formErrors.password}
                      />
                      {formErrors.password && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridConfirmPassword">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirm_password"
                        onChange={handleUserChange}
                        value={user.confirm_password}
                        isInvalid={formErrors.confirm_password}
                      />
                      {formErrors.confirm_password && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.confirm_password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-5">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Usertype</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="user_type"
                        onChange={handleUserChange}
                        value={user.user_type}
                        isInvalid={formErrors.user_type}
                      >
                        <option value="">Select the UserType</option>
                        {userType.map((item, idx) =>
                          item.user_type === "driver" ? (
                            <option key={idx} value={item.id}>
                              {item.user_type}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Select>
                      {formErrors.user_type && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.user_type}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600"> Status</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleUserChange}
                        value={user.status}
                      >
                        <option>Select the status</option>
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="address"
                        placeholder="Address"
                        onChange={handleChange}
                        value={data.address}
                        style={{ height: "100px" }}
                        isInvalid={formErrors.address}
                      />
                      {formErrors.address && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.address}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="choose your file"
                        name="image"
                        accept="image/jpeg,image/png,image/gif"
                        onChange={handleImage}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4">
                        {" "}
                        Truck info
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="truck"
                        onChange={handleChange}
                        value={data.truck}
                        isInvalid={formErrors.truck}
                      >
                        <option value="">Select the truck</option>
                        {truck.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.truck_name}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.truck && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.truck}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group>
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td>
                              <b>Truck </b>
                            </td>
                            <td>
                              <b>{truckDetials && truckDetials.truck_name}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Plate Number </b>
                            </td>
                            <td>
                              <b>{truckDetials && truckDetials.plate_number}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4">
                        {" "}
                        Trailer info
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="trailer"
                        onChange={handleChange}
                        value={data.trailer}
                        isInvalid={formErrors.trailer}
                      >
                        <option value="">Select the trailer</option>
                        {trailer.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.trailer_name}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.trailer && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.trailer}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group>
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td>
                              <b>Trailer</b>
                            </td>
                            <td>
                              <b>
                                {trailerDetials && trailerDetials.trailer_name}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Plate Number</b>
                            </td>
                            <td>
                              <b>
                                {trailerDetials && trailerDetials.plate_number}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4"> Manager </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="manager"
                        onChange={handleChange}
                        value={data.manager}
                        isInvalid={formErrors.manager}
                      >
                        <option>Select manager</option>
                        {manager.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.first_name + item.last_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    {formErrors.manager && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.manager}
                        </Form.Control.Feedback>
                      )}
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={12} sm={12} xs={12} md={12}>
            <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
              <Card.Body className="text-start">
                <Button
                  variant="primary"
                  type="submit"
                  className="rounded-5 shadow-sm w-25"
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Create Driver
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DriverForm;
