import {
  faPaperPlane,
  faPlusCircle,
  faSmile,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddModal from "../../Components/Model";
import { useState } from "react";

const LiveInput = () => {
  const [show, setShow] = useState(false);
  const handleClose = (value) => {
    setShow(value);
  };

  return (
    <section className="chat">
      <div className="header-chat">
        <FontAwesomeIcon className="icon" icon={faUser}/>
        <p className="name"><b>Driver name</b> <small>Postion nr: 00123456789</small> </p> 
      
        <FontAwesomeIcon
          className="icon_bubble clickable msg right"
          icon={faPlusCircle}
          onClick={() => setShow(true)}
        />
        {show ? (
          <AddModal showMod={show} onClose={handleClose} />
        ) : (
          <AddModal showMod={false} />
        )}
      </div>
      <div className="position-relative messagebox">
        <div className="chat-messages p-4">
          <div className="chat-message-left pb-4">
            <div>
              <div className="text-muted  text-nowrap mb-2 chat_name">
                driver Name 
              </div>
              <img
                src={undraw_profile}
                className="rounded-circle"
                alt="Sharon Lessman"
                width="50"
                height="50"
              />
              <div className="chat-online"></div>
            </div>
            <div className="flex-shrink-1 bg-light rounded  ml-3 chat_box">
              Sit meis deleniti eu, pri vidit meliore docendi ut, an eum erat
              animal commodo.
              <span className="time">send : 14h58</span>
            </div>
          </div>

          <div className="chat-message-right pd-4">
            <div>
              {/* <img
                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                className="rounded-circle mr-1"
                alt="Chris Wood"
                width="40"
                height="40"
              />
              <div className="text-muted small text-nowrap mt-2">drivername</div> */}
            </div>
            <div className="flex-shrink-1 rounded py-2 px-3 mr-3 ">
              Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset
              te vix.
              <span className="time">send : 14h58</span>
            </div>
          </div>
          <span className="media-time">read : 14h58</span>
        </div>
      </div>
      <div className="footer-chat">
        {/* <FontAwesomeIcon
          className="icon clickable"
          style={{ fontSize: "25pt" }}
          icon={faSmile}
        /> */}
        <input
          type="text"
          className="write-message"
          placeholder="Type your message here"
        />
        <FontAwesomeIcon className="icon send clickable" icon={faPaperPlane} />
      </div>
    </section>
  );
};

export default LiveInput;
