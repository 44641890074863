import React, { useEffect, useState } from "react";
import { Card, Col, Image, Table, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import undraw_profile from "../../../src/assets/images/undraw_profile.svg";
import "./users.css";
import Badge from "react-bootstrap/Badge";
import CardHeader from "../Components/CardHeader";
import { faArrowLeft, faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DriverMangerModal from "./../Components/DriverMangerModal";
import { getDriversByManagerId, getUserById } from "../services/users";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to users",
    buttonUrl: "../users",
    icon: faArrowLeft,
  },
];
const UserDetailView = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [drivers, setDrivers] = useState([]);

  const handleClose = (value) => {
    setShow(value);
  };

  const managerId = data.id;
  const driverList = drivers.map((item) => item.id);

  const refreshdata = () => {
    getUserById(id)
      .then((res) => {
        setData(res.data);
      })
  };

  useEffect(() => {
    refreshdata();
    if (managerId !== undefined) {
      getDriversByManagerId(managerId).then((res) => setDrivers(res.data));
    }
  }, [id, managerId]);

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="USER" />

      <div className="row">
        <Col sm={4} lg={4} xs={4}>
          <Card className="mb-3 p-5 border-0 shadow-sm">
            <Card.Body>
              <div className="row">
                <div className="col-12">
                  <Card.Img
                    style={{ width: "100px", borderRadius: "10px" }}
                    src={data.image || undraw_profile}
                    alt="Generic placeholder image"
                    as={Image}
                    fluid
                  />
                </div>
                <div className="col-12">
                  <Card.Title className="mt-3 mb-2">
                    <strong>{data.first_name + " " + data.last_name}</strong>
                  </Card.Title>
                  <Card.Text>
                    <Badge bg="danger p-2  shadow">
                      {data.user_type && data["user_type"].user_type}
                    </Badge>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={8} lg={8} xs={8}>
          <Card className="mb-3 p-2 border-0 shadow-sm">
            <Card.Body>
              <Table bordeless hover>
                <tbody>
                  <tr>
                    <td>
                      <b>Name</b>
                    </td>
                    <td colSpan={2}>{data.first_name}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Email</b>
                    </td>
                    <td colSpan={2}>{data.email || "Email@gmail.com"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Phone No</b>
                    </td>
                    <td colSpan={2}>{data.phone_number || "12345678890"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>User Type</b>
                    </td>
                    <td colSpan={2}>
                      {data.user_type && data.user_type.user_type}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Status</b>
                    </td>
                    <td colSpan={2}>
                      {" "}
                      <Badge bg="success p-2  shadow">
                        {data.status === 1 ? "Active" : "Not Active"}
                      </Badge>{" "}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
            <p className="text-left">
              Last Login <b>{data.last_login}</b>
            </p>
          </Card>
        </Col>
        {drivers.length > 0 ? (
          <Col sm={8} lg={12} xs={8}>
            <Card className="mb-3 p-2 border-0 shadow">
              <Card.Header>
                <Row>
                  <Col sm={12} lg={6} xs={12} md={6}>
                    <p className="h6 text-start">
                      <b>Assigned Drivers List</b>
                    </p>
                    <p className="h6 text-start">
                      {/* <Link
                      to="/dashboard/assignDriver"
                      relative="users"
                      className=" btn  btn-sm btn-primary shadow rounded-5 w-25 ml-3"
                    >
                      <FontAwesomeIcon icon={faCircleUser} className="mr-3" />
                      Manage
                    </Link> */}
                      <Link
                        onClick={() => setShow(true)}
                        relative="users"
                        className=" btn  btn-sm btn-primary shadow rounded-5 w-25 ml-3"
                      >
                        <FontAwesomeIcon
                          diverId={id}
                          icon={faCircleUser}
                          className="mr-3"
                        />
                        Change Manager
                      </Link>
                      {show ? (
                        <DriverMangerModal
                          driversId={driverList}
                          showMod={show}
                          onClose={handleClose}
                          refreshList={refreshdata}
                        />
                      ) : (
                        <DriverMangerModal showMod={false} />
                      )}
                    </p>
                  </Col>
                </Row>
              </Card.Header>

              <Card.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th> Name</th>
                      <th>Truck</th>
                      <th>Platenumber</th>
                      <th>Trailer</th>
                      <th>Platenumber</th>
                    </tr>
                  </thead>
                  <tbody>
                    {drivers.map((item) => (
                      <tr>
                        <td>1</td>
                        <td>
                          {item.user.first_name + " " + item.user.last_name}
                        </td>
                        <td>{item.truck.truck_name}</td>
                        <td>{item.truck.plate_number}</td>
                        <td>{item.trailer.trailer_name}</td>
                        <td>{item.trailer.plate_number}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        ) : null}
      </div>
    </div>
  );
};

export default UserDetailView;
