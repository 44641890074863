import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import CheckBox from "./CheckBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const data = [
  {
    id: 1,
    first_name: "loott",
    last_name: " 2023-03-17",
    plateNo: "2023-03-24",
    is_assigned: true,
  },
  {
    id: 2,
    first_name: "tiny",
    last_name: " 2023-03-17",
    plateNo: "2023-03-24",
    is_assigned: false,
  },
  {
    id: 3,
    first_name: "lint",
    last_name: " 2023-03-17",
    plateNo: "2023-03-24",
    is_assigned: true,
  },
];

const AddModal = ({ showMod, onClose }) => {
  const [checked, setChecked] = useState(new Array(data.length).fill(false));
  const [inputtext, setInputText] = useState(" ");

  const handleCheck = (id, value) => {
    console.log(id);
    setChecked(checked[id-1]=value);
  };
  const handleSearch = (e) => {
    setInputText(e.target.value);
  };
  const filteredData = data.filter((data) => {
    return (
      data.first_name.toLowerCase().includes(inputtext.toLowerCase()) ||
      data.last_name.toLowerCase().includes(inputtext.toLowerCase())
    );
  });
  const handleClose = (showMod) => onClose(!showMod);
  return (
    <Modal     
      className="modal-xl"
      show={showMod}
      onHide={handleClose}
    >
      
      <Modal.Header>
        <Modal.Title>Add Participants</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ width: "100wh" }}>
        <div className="assign_search">
          <div className="searchbar">
            <FontAwesomeIcon icon={faSearch} />
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        </div>
        <table className="table  mt-2">
          <tbody>
            {filteredData.map((item) => (
              <CheckBox
                row={item}
                key={item.id}
                callback={handleCheck}
                checked={checked}
              />
            ))}
          </tbody>
        </table>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button variant="primary">Add</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddModal;
