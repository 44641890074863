import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Card from "react-bootstrap/Card";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

export const DashCard = ({ text,value, icon, cssStyle }) => {
  const titleStyle = {
    "float": "left",
    "fontSize": "2rem",
    "fontWeight": "800"
  }
  return (
    <div className="col-xl-3 col-md-6 mb-4">
      <Card className={"shadow-sm " + cssStyle}>
        <Card.Body>
          <div className="content">
            <div>
              <Card.Title style={titleStyle}><b>{value}</b></Card.Title>
              <Card.Subtitle className="fw-bold">
                {text}
              </Card.Subtitle>
            </div>
            <FontAwesomeIcon className="icon" icon={icon}/>
          </div>

        </Card.Body>
        <div className="card-button">
        <Card.Link href="#">More info <FontAwesomeIcon icon={faCircleArrowRight}/></Card.Link>
        </div>
          
      </Card>
    </div>
  );
};
