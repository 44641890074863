import React, { useState, useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { toastSuccess } from "../../Components/ToastNotification";
import { redirect } from "react-router-dom";
import CardHeader from "../../Components/CardHeader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getDriver } from "../../services/users";
import DataTable from "react-data-table-component";
import FilterComponent from "../../SearchBox";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back",
    buttonUrl: -1,
    icon: faArrowLeft,
  },
];

const AssignDriver = () => {
  const [rowState, setRowState] = useState([]);
  const [selectValue, setSelectedValue] = useState([]);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getDriver().then((res) => setRowState(res.data));
  }, []);
  const columns = [
    {
      name: "#",
    },
    {
      name: "No",
      selector: (row) => rowState.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.user.first_name + " " + row.user.last_name,
      sortable: true,
    },
    {
      name: "truck",
      selector: (row) => row.truck.truck_name,
      sortable: true,
    },
    {
      name: "truck plate Number",
      selector: (row) => row.truck.plate_number,
      sortable: true,
    },
    {
      name: "trailer",
      selector: (row) => row.trailer.trailer_name,
      sortable: true,
    },
    {
      name: "trailer plate Number",
      selector: (row) => row.trailer.plate_number,
      sortable: true,
    },
  ];

  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <div className="form-check " style={{ backgroundColor: "" }}>
          <input
            type="checkbox"
            className="form-check-input"
            style={{ height: "20px", width: "20px" }}
            ref={ref}
            onClick={onClick}
            {...rest}
          />
          <label className="form-check-label" id="booty-check" />
        </div>
      </>
    );
  });

  const filteredData = rowState.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const onCheckboxChange = (e) => {
    const IdArr = e.selectedRows.map((item) => item.id);
    setSelectedValue(IdArr);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toastSuccess("Successfully created user");
    redirect("/");
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title=" Assign Driver" />
      <Card className="mb-3 border-0 shadow-sm">
        <Card.Body>
          <div className="form-wrapper">
            <Form
              noValidate
              className="needs-validation"
              onSubmit={handleSubmit}
            >
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                subHeader
                highlightOnHover
                subHeaderComponent={subHeaderComponent}
                selectableRows
                selectableRowsComponent={Checkbox}
                onSelectedRowsChange={onCheckboxChange}
              />

              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5 mt-3 mb-3"
              >
                assign drivers
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default AssignDriver;
