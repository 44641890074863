import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CardHeader(props) {
  const btnStyle = {
    marginRight: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    textTransform: "uppercase",
  };
  const marginStyle = { marginLeft: "20px", textTransform: "uppercase" };


  return (
    <>
      <Card className="mb-3 border-0 shadow-sm  rounded-5">
        <Card.Body>
          <div className="d-sm-flex align-items-center justify-content-between ">
            <h1
              className="h6 mb-0 text-gray-800 fw-bold ml-4"
              style={marginStyle}
            >
              {props.title}
            </h1>
            <div className="mr-3">
              {props.headerItems.map((obj, index) => (
                <Link
                  to={obj.buttonUrl}
                  relative="users"
                  className="d-none d-sm-inline-block mr-2 btn  btn-sm btn-primary shadow rounded-5"
                  style={btnStyle}
                  key={index}
                >
                  <FontAwesomeIcon icon={obj.icon} className="mr-3" />
                  {obj.buttonTitle}
                </Link>
              ))}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default CardHeader;
