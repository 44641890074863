import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import undraw_profile from "../../../assets/images/undraw_profile.svg";

const LiveUserList = () => {
  const [inputtext, setInputText] = useState(" ");
  const handleSearch = (e) => {
    setInputText(e.target.value);
  };
  return (
    <section className="discussions">
      <div className="px-4 d-none d-md-block">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <input
              type="text"
              className="form-control my-3"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <Link className="list-group-item list-group-item-action border-0">
        <div className="discussion message-active">
          <div
            className="photo"
            style={{
              backgroundImage: undraw_profile,
            }}
          >
            {/* <div className="online" /> */}
          </div>
          <div className="desc-contact text-start">
            <p className="name">Driver name </p>
            <p className="message">00123456789/ Load Type</p>
          </div>
          {/* <Badge bg="danger">5</Badge> */}
        </div>
      </Link>
      <Link className="list-group-item list-group-item-action border-0">
        <div className="discussion">
          <div
            className="photo"
            style={{
              backgroundImage: undraw_profile,
            }}
          >
            {/* <div className="online" /> */}
          </div>
          <div className="desc-contact text-start">
          <p className="name">Driver name </p>
            <p className="message">00123456789 / Load Type</p>
          </div>
          {/* <Badge bg="danger">2</Badge> */}
        </div>
      </Link>
      <Link className="list-group-item list-group-item-action border-0">
        <div className="discussion">
          <div
            className="photo"
            style={{
              backgroundImage: undraw_profile,
            }}
          ></div>
          <div className="desc-contact text-start">
          <p className="name">Driver name </p>
            <p className="message">00123456789 / Load Type</p>
          </div>
          <Badge></Badge>
        </div>
      </Link>
      <Link className="list-group-item list-group-item-action border-0">
        <div className="discussion">
          <div
            className="photo"
            style={{
              backgroundImage: undraw_profile,
            }}
          >
            <div className="online" />
          </div>
          <div className="desc-contact text-start">
          <p className="name">Driver name </p>
            <p className="message">00123456789/ Load Type</p>
          </div>
          <Badge></Badge>
        </div>
      </Link>
      <Link className="list-group-item list-group-item-action border-0">
        <div className="discussion">
          <div
            className="photo"
            style={{
              backgroundImage: undraw_profile,
            }}
          ></div>
          <div className="desc-contact text-start">
          <p className="name">Driver name </p>
            <p className="message">00123456789/ Load Type</p>
          </div>
          <Badge></Badge>
        </div>
      </Link>
      
    </section>
  );
};

export default LiveUserList;
