import React from "react";
import SideBar from "../../pages/Dashboard/Sidebar/Sidebar";
import "./AdminLayout.css";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import NavbarTop from "./../Dashboard/NavbarTop/NavbarTop";
import NavBarComp from "../context/NavBarContext";

function AdminLayout() {
  return (
    <NavBarComp>
      <div className="wrapper">
        <SideBar />
        <ToastContainer theme="colored" />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <NavbarTop />
            <Outlet />
          </div>
        </div>
      </div>
    </NavBarComp>
  );
}

export default AdminLayout;
