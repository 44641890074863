import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Datetime from "react-datetime";

export const UnpdateUnloadlocationsModal = ({
  title,
  showMod,
  onClose,
  loaddata,
  onSubmit,
}) => {
  const [data, setData] = useState([]);
  const [date, setDate] = useState(Date(loaddata && loaddata.second_loading_date));

  const handleClose = (showMod) => onClose(!showMod);

  const formatDate = (date) => {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = "" + d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  useEffect(() => {
    setData(loaddata);
  }, [loaddata]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

 
  const handleAdd = (showMod) => {
    if (data) {
      const format_date = formatDate(date);
      data["second_unloading_date"] = format_date;
      onSubmit(data);
      onClose(!showMod);
    }
  };
  return (
    <Modal className="modal-xl" show={showMod} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Second {title} Location</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ width: "100wh" }}>
        <Card className="mb-3 border-0 shadow-sm rounded-2">
          <Card.Body>
            <div className="form-wrapper">
              <Row className="mb-3">
                <Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-600">{title} Location</Form.Label>
                    <Form.Control
                      type="text"
                      name="second_unloading_location"
                      onChange={handleChange}
                      value={data && data.second_unloading_location}
                      className="border-2"
                      placeholder="Enter the location"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="fw-600">{title} Date</Form.Label>
                    <Datetime
                      name="second_unloading_date"
                      value={date}
                      onChange={(date) => setDate(date)}
                      timeFormat={false}
                    />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-600">{title} Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="second_unloading_country"
                    onChange={handleChange}
                    value={data && data.second_unloading_country}
                    className="border-2"
                    placeholder="Enter the Country"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-600">
                    {title} Country Code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="second_unloading_country_code"
                    onChange={handleChange}
                    value={data && data.second_unloading_country_code}
                    className="border-2"
                    placeholder="Enter the Country Code"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-600">{title} Reference</Form.Label>
                  <Form.Control
                    type="text"
                    name="second_unloading_reference"
                    onChange={handleChange}
                    value={data && data.second_unloading_reference}
                    className="border-2"
                    placeholder="Enter the Reference"
                  />
                </Form.Group>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button onClick={handleAdd} variant="primary">
          Add Second {title} Location
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
