import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Login";
import NotFound from "../NotFound";
import Uttiles from "../NextPage";
import MainContent from "../Dashboard/Content/content";
import LoadPage from "../loads/LoadPage";
import Users from "./../Users/Users";
import UserForm from "../Users/forms/UserForm";
import Drivers from "../Drivers/Drivers";
import DriverForm from "./../Drivers/forms/DriverForm";
import UserDetailView from "../Users/UserDetailView";
import DriverDetailView from "../Drivers/DriverDetailView";
import UserUpdateForm from "../Users/forms/UserUpdate";
import DriverUpdateForm from "../Drivers/forms/DriverUpdateForm";
import AssignDriver from "../Users/forms/AssignDriver";
import LoadForm from "./../loads/forms/loadForm";
import LoadUpdate from "../loads/forms/loadUpdate";
import AdminLayout from "../Layout/AdminLayout";
import Usertypes from "./../Usertypes/Usertypes";
import UsertypeAdd from "../Usertypes/form/UsertypeAdd";
import UsertypeUpdate from "../Usertypes/form/UsertypeUpdate";
import Trucks from "../Trucks/Trucks";
import TruckAdd from "../Trucks/form/TruckAdd";
import TruckUpdate from "../Trucks/form/TruckUpdate";
import Trailers from "../Trailers/Trailers";
import TrailerAdd from "../Trailers/form/TrailerAdd";
import TrailerUpdate from "../Trailers/form/TrailerUpdate";
import LoadDetailView from "./../loads/LoadDetailView";
import LiveLoads from "./../loads/Chats/LiveLoads";
import Tickets from "./../Tickets/Tickets";
import TicketAddForm from "./../Tickets/forms/TicketAddForm";
import TicketType from "../TicketType/TicketType";
import TicketTypeAdd from "./../TicketType/form/TicketTypeAdd";
import TicketTypeUpadate from "../TicketType/form/TicketTypeUpadate";
import TicketUpdateForm from "../Tickets/forms/TicketUpdateForm";
import TicketDetials from "../Tickets/TicketDetials";
import PendingLoad from "../loads/PendingLoad";
import ClosedLoad from "../loads/ClosedLoad";
import LoadingLoad from "../loads/LoadingLoad";
import ClosedChat from "../loads/Chats/ClosedChat";
import TicketClosed from "../Tickets/TicketClosed";
import TicketPending from "../Tickets/TicketPending";
import DriverGroup from "../Drivers/DriverGroup/DriverGroup";
import AddDriversGroup from "./../Drivers/DriverGroup/Forms/AddDriversGroup";
import DriverGroupDetails from "./../Drivers/DriverGroup/DriverGroupDetails";
import UpdateDriversGroup from "../Drivers/DriverGroup/Forms/UpdateDriversGroup";
import DriverGroupMessage from "../Drivers/DriverGroupMessage/DriverGroupMessage";
import AddDriverMessage from "../Drivers/DriverGroupMessage/forms/AddDriverMessage";
import UpdateDriverMessage from "../Drivers/DriverGroupMessage/forms/UpdateDriverMessage";
import DriverMessageDetail from "../Drivers/DriverGroupMessage/DriverMessageDetail";
import TicketOpen from "../Tickets/TicketOpen";
import LoadComplete from "../loads/LoadComplete";
import TransitLoad from "../loads/TransitLoad";
import PendingLoadTwo from "../loads/PendingLoadTwo";
import { ProtectedRoutes } from "./ProtectedRoutes";

const MainRoutes = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route index element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="dashboard" element={<AdminLayout />}>
            <Route index element={<MainContent />} />
            <Route path="users" element={<Users />} />
            <Route path="add/user" element={<UserForm />} />
            <Route exact path="users/detail/:id" element={<UserDetailView />} />
            <Route path="users/update/:id" element={<UserUpdateForm />} />

            <Route path="usertypes" element={<Usertypes />} />
            <Route path="add/usertype" element={<UsertypeAdd />} />
            <Route path="update/usertype/:id" element={<UsertypeUpdate />} />

            <Route path="trucks" element={<Trucks />} />
            <Route path="add/truck" element={<TruckAdd />} />
            <Route path="update/truck/:id" element={<TruckUpdate />} />

            <Route path="trailers" element={<Trailers />} />
            <Route path="add/trailer" element={<TrailerAdd />} />
            <Route path="update/trailer/:id" element={<TrailerUpdate />} />

            <Route path="assignDriver" element={<AssignDriver />} />
            <Route path="drivers" element={<Drivers />} />
            <Route path="add/driver" element={<DriverForm />} />
            <Route path="detail/driver/:id" element={<DriverDetailView />} />
            <Route path="update/driver/:id" element={<DriverUpdateForm />} />

            <Route path="driversgroup" element={<DriverGroup />} />
            <Route path="add/driversgroup" element={<AddDriversGroup />} />
            <Route
              path="update/driversgroup/:id"
              element={<UpdateDriversGroup />}
            />
            <Route
              path="detail/driversgroup/:id"
              element={<DriverGroupDetails />}
            />

            <Route
              path="driversgroupmessage"
              element={<DriverGroupMessage />}
            />
            <Route
              path="add/driversgroupmessage"
              element={<AddDriverMessage />}
            />
            <Route
              path="update/driversgroupmessage/:id"
              element={<UpdateDriverMessage />}
            />
            <Route
              path="detail/driversgroupmessage/:id"
              element={<DriverMessageDetail />}
            />

            <Route path="loads" element={<LoadPage />} />
            <Route path="pendingloads" element={<PendingLoad />} />
            <Route path="pendingloadstwo" element={<PendingLoadTwo />} />
            <Route path="closedloads" element={<ClosedLoad />} />
            <Route path="completedloads" element={<LoadComplete />} />
            <Route path="transitload" element={<TransitLoad />} />
            <Route path="loadingloads" element={<LoadingLoad />} />
            <Route path="add/load" element={<LoadForm />} />
            <Route path="detail/load/:id" element={<LoadDetailView />} />
            <Route path="update/load/:id" element={<LoadUpdate />} />
            <Route path="loads/onlive" element={<LiveLoads />} />
            <Route path="loads/onclosed" element={<ClosedChat />} />

            <Route path="tickets" element={<Tickets />} />
            <Route path="tickets/closed" element={<TicketClosed />} />
            <Route path="tickets/pending" element={<TicketPending />} />
            <Route path="tickets/open" element={<TicketOpen />} />
            <Route path="add/tickets" element={<TicketAddForm />} />
            <Route path="detail/tickets/:id" element={<TicketDetials />} />
            <Route path="update/tickets/:id" element={<TicketUpdateForm />} />

            <Route path="tickettypes" element={<TicketType />} />
            <Route path="add/tickettypes" element={<TicketTypeAdd />} />
            <Route
              path="update/tickettypes/:id"
              element={<TicketTypeUpadate />}
            />

            <Route path="uttiles" element={<Uttiles />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
};

export default MainRoutes;
