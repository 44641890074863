import { APIURL } from "../../constants/constants";
import http from "./ServerApi";


export const getUserType = async () => {
  return await http.get(APIURL + "/user_type/");
};

export const getUserByIdType = async (id) => {
  return await http.get(APIURL + `/user_type/${id}/`);
};

export const addUserType = async (data) => {
    return await http.post(APIURL + "/user_type/add/",data);
};
export const updateUserType = async (id,data) => {
  return await http.update(APIURL + `/user_type/update/${id}/`,data);
};

export const deleteUserType = async (id) => {
    return await http.delete(APIURL + `/user_type/delete/${id}/`);
};
  