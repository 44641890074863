import React from "react";
import Chart from "react-apexcharts";

class TruckApexChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [50,50],
      options: {
        labels:["loaded","unload"],
        chart: {
          width: 380,
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    };
  }


  render() {
    return (
      <div>
        <div className="chart-wrap">
          <div id="chart">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="donut"
              width={380}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TruckApexChart;
