import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import React, { useState,useEffect } from "react";
import "./users.css";
import Card from "react-bootstrap/Card";
import Table from "../Components/Tables";
import TableActionButton from "../Components/TableActionButton";
import CardHeader from "../Components/CardHeader";
import { getUsers,deleteUser } from "../services/users";

const headerButtons = [
  {
    buttonTitle: "Add User",
    buttonUrl: "/dashboard/add/user",
    icon: faUserPlus,
  },
];

const Users = () => {
  const [data,setData] = useState([])

  const userList = () =>{
    getUsers().then(res=>setData(res.data))
  }

  useEffect(()=>{
   userList()
  },[])

  const columns = [
    {
      name: "serialNo",
      selector: (row) =>data.indexOf(row)+1,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.first_name + " " + row.last_name,
      sortable: true,
    },
    {
      name: "email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "usertype",
      selector: (row) => row.user_type.user_type,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/users/detail/${row.id}`}
          UpdatePath={`/dashboard/users/update/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteUser}
          refreshdata={userList}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="USERS" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-2">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Users;
