import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getLoads = async () => {
  return await http.get(APIURL + "/loads/");
};
export const addLoads = async (data) => {
  return await http.post(APIURL + "/loads/add/", data);
};
export const getByIdLoads = async (id) => {
  return await http.get(APIURL + `/loads/${id}/`);
};
export const getByLoadStatus = async (id) => {
  return await http.get(APIURL + `/loads/?loading_status=${id}`);
};

export const updateLoads = async (id,data) => {
  return await http.update(APIURL + `/loads/update/${id}/`,data);
};

export const deleteLoads = async (id) => {
  return await http.delete(APIURL + `/loads/delete/${id}/`);
};
