import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import Table from "../Components/Tables";
import TableActionButton from "../Components/TableActionButton";
import CardHeader from "../Components/CardHeader";
import { useNavigate } from "react-router-dom";
import { getDriver, deleteDriver } from "./../services/users";
import { useState } from "react";
import { faTruckArrowRight } from "@fortawesome/free-solid-svg-icons";

const headerButtons = [
  {
    buttonTitle: "Add Driver",
    buttonUrl: "/dashboard/add/driver",
    icon: faTruckArrowRight,
  },
];

const Drivers = () => {
  const [data, setData] = useState([]);
  const navgate = useNavigate();
  
 const driverData = () =>{
  getDriver().then((res) => setData(res.data));
 }
  useEffect(() => {
    driverData()
  },[]);
  
  const columns = [
    {
      name: "serialNo",
      selector: (row) => data.indexOf(row)+1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) =>row.user && row.user.first_name +" " + row.user.last_name,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Truck",
      selector: (row) => row.truck && row.truck.truck_name,
    },
    {
      name: "Trailer",
      selector: (row) => row.trailer && row.trailer.trailer_name,
    },
    {
      name: "Manager",
      selector: (row) => row.manager && row.manager.first_name + " "+ row.manager.last_name,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/driver/${row.id}`}
          UpdatePath={`/dashboard/update/driver/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteDriver}
          refreshdata={driverData}
        />
      ),
    },
  ];
  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Drivers" />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Drivers;
