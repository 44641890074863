import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getDriverGroups = async () => {
  return await http.get(APIURL + "/Driver-Group/");
};
export const addDriverGroup = async (data) => {
  return await http.post(APIURL + "/Driver-Group/create/", data);
};
export const getDriverGroupById = async (id) => {
  return await http.get(APIURL + `/Driver-Group/${id}/`);
};

export const updateDriversGroup = async (id,data) => {
  return await http.update(APIURL + `/Driver-Group/update/${id}/`,data);
};

export const deleteDriverGroups = async (id) => {
  return await http.delete(APIURL + `/Driver-Group/delete/${id}/`);
};
