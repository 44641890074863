import React from "react";
import LiveInput from "./LiveInput";
import LiveUserList from "./LiveUserList";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function LiveLoads() {
  return (
      <div className="container-fluid">
        <Row>
          <Col sm={3}>
            <LiveUserList />
          </Col>
          <Col sm={9}>
            <LiveInput />
          </Col>
        </Row>
      </div>
  );
}

export default LiveLoads;
