import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getTrucks = async () => {
  return await http.get(APIURL + "/trucks/");
};
export const addTrucks = async (data) => {
  return await http.post(APIURL + "/trucks/create/", data);
};
export const getByIdTrucks = async (id) => {
  return await http.get(APIURL + `/trucks/${id}/`);
};

export const updateTrucks = async (id,data) => {
  return await http.update(APIURL + `/trucks/update/${id}/`,data);
};
export const deleteTrucks = async (id) => {
  return await http.delete(APIURL + `/trucks/delete/${id}/`);
};
