import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getTrailers = async () => {
  return await http.get(APIURL + "/trailer/");
};
export const addTrailers = async (data) => {
  return await http.post(APIURL + "/trailer/create/", data);
};
export const getTrailersByid = async (id) => {
  return await http.get(APIURL + `/trailer/${id}/`);
};

export const deleteTrailers = async (id) => {
  return await http.delete(APIURL + `/trailer/delete/${id}/`);
};

export const updateTrailers = async (id, data) => {
  return await http.update(APIURL + `/trailer/update/${id}/`, data);
};
