import React, { useState, useMemo } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastSuccess } from "../../../Components/ToastNotification";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../SearchBox";
import { useEffect } from "react";
import {
  getDriverGroupById,
  updateDriversGroup,
} from "../../../services/DriverGroups";
import { useNavigate, useParams } from "react-router-dom";
import { getDriver } from "../../../services/users";
import { getjwt } from "../../../services/authServices";

const groupValidator = (value, diverlist) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Group Name is required";
  }
  if (diverlist && diverlist.length > 0) {
    errors.diverlist = "manager is required";
  }
  if (!value["drivers"]) {
    errors.drivers = "manager is required";
  }
  return errors;
};

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../driversgroup", icon: faArrowLeft },
];

const UpdateDriversGroup = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [drivers, setDrivers] = useState([]);
  const [selectValue, setSelectedValue] = useState([]);

  const filteredItems = data.drivers && data.drivers.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  console.log(filteredItems)

  const columns = [
    {
      name: "#",
    },
    {
      name: "No",
      selector: (row) => filteredItems && filteredItems.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "name",
      selector: (row) => row.user.first_name + " " + row.user.last_name,
      sortable: true,
    },
    {
      name: "truck",
      selector: (row) => row.truck.truck_name,
      sortable: true,
    },
    {
      name: "trailer",
      selector: (row) => row.trailer.trailer_name,
      sortable: true,
    },
  ];
  const onCheckboxChange = (e) => {
    const IdArr = e.selectedRows.map((item) => item.id);
    setSelectedValue(IdArr);
  };

  useEffect(() => {
    getDriverGroupById(id).then((res) => setData(res.data));
    getDriver().then((res) => setDrivers(res.data));
  }, [id]);

  const Checkbox = React.forwardRef(({ onClick, ...rest }, ref) => {
    return (
      <>
        <div className="form-check " style={{ backgroundColor: "" }}>
          <input
            type="checkbox"
            className="form-check-input"
            style={{ height: "20px", width: "20px" }}
            ref={ref}
            onClick={onClick}
            {...rest}
          />
          <label className="form-check-label" id="booty-check" />
        </div>
      </>
    );
  });

  const subHeaderComponent = useMemo(() => {
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        filterText={filterText}
      />
    );
  }, [filterText]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setFormErrors(groupValidator(inputs, selectValue));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    data["token_data"] = JSON.parse(token);
    if (selectValue.length > 0) {
      console.log("first");
      data["drivers"] = selectValue;
    }
    data["drivers"] = data.drivers.map((item) => item.id);
    if (data.title !== "" && data.drivers !== "") {
      updateDriversGroup(id, data).then((res) => {
        toastSuccess("Successfully Updated DriverGroup");
        navgate("../driversgroup");
      });
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Update GROUP" />

      <Card className="mb-3 border-0 shadow-sm rounded-5">
        <Card.Body>
          <div className="form-wrapper">
            <Form
              noValidate
              className="needs-validation"
              onSubmit={handleSubmit}
            >
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label htmlFor="text" className="fw-600">
                    Group Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Add a Group Name"
                    onChange={handleChange}
                    value={data.title}
                    className="border-2"
                    isInvalid={formErrors.title}
                  />
                  {formErrors.title && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="multiselect_field">
                  <Form.Label className="fw-600">Select Drivers</Form.Label>
                  <div>
                    {formErrors.diverlist && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.diverlist}
                        {console.log(formErrors)}
                      </Form.Control.Feedback>
                    )}
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredItems && filteredItems}
                    pagination
                    paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                    subHeader
                    highlightOnHover
                    subHeaderComponent={subHeaderComponent}
                    selectableRows
                    selectableRowsComponent={Checkbox}
                    onSelectedRowsChange={onCheckboxChange}
                  />
                </Form.Group>
              </Row>
              <hr></hr>
              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Update Group
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default UpdateDriversGroup;
