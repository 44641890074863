import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import "./forms.css";
import { Col, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Table from "react-bootstrap/Table";
import CardHeader from "../../Components/CardHeader";
import { toastSuccess } from "../../Components/ToastNotification";
import { getByIdTrucks, getTrucks } from "../../services/trucks";
import {
  UpdateDriver,
  getDriverDetails,
  getManager,
} from "../../services/users";
import { getTrailers, getTrailersByid } from "../../services/trailer";
import { useEffect } from "react";
import RegisterValidater from "../../Components/validators/RegisterValidater";
import { getjwt } from "../../services/authServices";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../drivers", icon: faArrowLeft },
];

const driverValidator = (value) => {
  let errors = {};
  if (!value["address"]) {
    errors.address = "Address is required";
  }
  if (!value["truck"]) {
    errors.truck = "truck is required";
  }
  if (!value["trailer"]) {
    errors.trailer = "trailer is required";
  }
  if (!value["manager"]) {
    errors.manager = "manager is required";
  }
  return errors;
};

const DriverUpdateForm = () => {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [user, setUser] = useState();
  const [image, setImage] = useState("");
  const [truck, setTruck] = useState([]);
  const [truckDetials, setTruckDetials] = useState();
  const [trailer, setTrailer] = useState([]);
  const [trailerDetials, setTrailerDetials] = useState();
  const [manager, setManager] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const handleUserChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...user,
      [name]: value,
    };
    setUser({ ...user, [name]: value });
    setFormErrors(RegisterValidater(inputs));
  };
  const truckId = data.truck && data.truck.id;
  const trailerId = data.trailer && data.trailer.id;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setFormErrors(driverValidator(inputs));
    if (inputs.truck) {
      getByIdTrucks(inputs.truck).then((res) => setTruckDetials(res.data));
    }
    if (inputs.trailer) {
      getTrailersByid(inputs.trailer).then((res) =>
        setTrailerDetials(res.data)
      );
    }
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const driversTruck = () => {
    getTrucks().then((res) => setTruck(res.data));
  };
  const getMangerUser = () => {
    getManager().then((res) => setManager(res.data));
  };
  const driversTrailer = () => {
    getTrailers().then((res) => setTrailer(res.data));
  };

  useEffect(() => {
    driversTruck();
    driversTrailer();
    getMangerUser();
    getDriverDetails(id).then((res) => {
      setData(res.data);
      setUser(res.data.user);
    });
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    let form_data;
    if (
      data.address !== "" &&
      data.truck !== "" &&
      data.trailer !== "" &&
      data.manager !== ""
    ) {
      form_data = new FormData();
      user["token_data"] = JSON.parse(token);

      if (image) form_data.append("image", image);
      form_data.append("user", JSON.stringify(user));
      form_data.append("address", data.address);
      form_data.append("truck", data.truck.id || data.truck);
      form_data.append("trailer", data.trailer.id || data.trailer);
      form_data.append("manager", data.manager.id || data.manager);
      console.log(form_data);
      UpdateDriver(id, form_data)
        .then((res) => {
          toastSuccess("succesfully updated Drivers");
          navgate("../drivers");
        })
        .catch((err) => console.log(err));
    } else {
      setFormErrors(driverValidator(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Update Driver" />

      <Form noValidate className="needs-validation" onSubmit={handleSubmit}>
        <Row>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">First name</Form.Label>
                      <Form.Control
                        type="text"
                        name="first_name"
                        placeholder="First name"
                        onChange={handleUserChange}
                        value={user && user.first_name}
                        isInvalid={formErrors.first_name}
                      />
                      {formErrors.first_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.first_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Last name</Form.Label>
                      <Form.Control
                        type="text"
                        name="last_name"
                        placeholder="Last name"
                        onChange={handleUserChange}
                        value={user && user.last_name}
                        isInvalid={formErrors.last_name}
                      />
                      {formErrors.last_name && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.last_name}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Email</Form.Label>
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter the Email Address"
                        onChange={handleUserChange}
                        value={user && user.email}
                        isInvalid={formErrors.email}
                      />
                      {formErrors.email && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Phone No:</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone_number"
                        placeholder="Enter the Phone Number"
                        onChange={handleUserChange}
                        value={user && user.phone_number}
                        isInvalid={formErrors.phone_number}
                      />
                      {formErrors.phone_number && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.phone_number}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600"> Status</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleUserChange}
                        value={user && user.status}
                      >
                        <option value="">Select the status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-600">Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="address"
                        placeholder="Address"
                        onChange={handleChange}
                        value={data.address}
                        style={{ height: "100px" }}
                        isInvalid={formErrors.address}
                      />
                      {formErrors.address && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.address}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Image</Form.Label>
                      <Form.Control
                        type="file"
                        placeholder="Choose your image"
                        name="image"
                        accept="image/jpeg,image/png,image/gif"
                        onChange={handleImage}
                      />
                    </Form.Group>
                  </Row>
                  <Image
                    className="form_image"
                    alt="No Preview"
                    src={image || data.image}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4">
                        {" "}
                        Truck info
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="truck"
                        onChange={handleChange}
                        value={data.truck && data.truck.id}
                        isInvalid={formErrors.truck}
                      >
                        {truck.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.truck_name}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.truck && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.truck}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group>
                      {data.truck && data.truck !== undefined ? (
                        <>
  
                          <p>
                            Truck Name : {data.truck && data.truck.truck_name}
                          </p>
                          <p>
                            Plate Number :{" "}
                            {data.truck && data.truck.plate_number}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td>
                              <b>Truck </b>
                            </td>
                            <td>
                              <b>{truckDetials && truckDetials.truck_name}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Plate Number</b>
                            </td>
                            <td>
                              <b>{truckDetials && truckDetials.plate_number}</b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4">
                        {" "}
                        Trailer info
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="trailer"
                        onChange={handleChange}
                        value={data.trailer && data.trailer.id}
                        isInvalid={formErrors.trailer}
                      >
                        {trailer.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.trailer_name}
                          </option>
                        ))}
                      </Form.Select>
                      {formErrors.trailer && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.trailer}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group>
                    {data.trailer && data.truck ? (
                        <>
                          <p>
                            Trailer Name :{" "}
                            {data.trailer && data.trailer.trailer_name}
                          </p>
                          <p>
                            Plate Number :{" "}
                            {data.trailer && data.trailer.plate_number}
                          </p>
                        </>
                      ) : (
                        ""
                      )}
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <td>
                              <b>Trailer</b>
                            </td>
                            <td>
                              <b>
                                {trailerDetials && trailerDetials.trailer_name}
                              </b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Plate Number</b>
                            </td>
                            <td>
                              <b>
                                {trailerDetials && trailerDetials.plate_number}
                              </b>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label className="fw-600 mb-4"> Manager </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="manager"
                        onChange={handleChange}
                        value={data.manager && data.manager.id}
                      >
                        <option>Select manager</option>
                        {manager.map((item, idx) => (
                          <option key={idx} value={item.id}>
                            {item.first_name + item.last_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={12} sm={12} xs={12} md={12}>
            <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
              <Card.Body className="text-start">
                <Button
                  variant="primary"
                  type="submit"
                  className="rounded-5 shadow-sm w-25"
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Updata Driver
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default DriverUpdateForm;
