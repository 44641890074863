import React, { useState, useMemo } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import CardHeader from "../../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastSuccess } from "../../../Components/ToastNotification";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../SearchBox";
import RegisterValidater from "../../../Components/validators/RegisterValidater";


const driverData = [
  {
    id: 1,
    name: "Driver1",
    truck: "00124-56-89",
    manager: "manager1",
  },
  {
    id: 2,
    name: "Driver2",
    truck: "00124-56-89",
    manager: "manager1",
  },
  {
    id: 3,
    name: "Driver3",
    truck: "00124-56-89",
    manager: "manager1",
  },
];
const columns = [
  {
    name: "No",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "name",
    selector: (row) => row.name,
    sortable: true,
  },
];
const headerButtons = [
  { buttonTitle: "Back to messages", buttonUrl: "../driversgroupmessage", icon: faArrowLeft },
];

const INITIAL_DATA = {
  groupName: "",
  drivers: "",
};

const UpdateDriverMessage = () => {
  const [data, setData] = useState(INITIAL_DATA);
  const [formErrors, setFormErrors] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectValue, setSelectedValue] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const onCheckboxChange = (e) => {
    // var { checked, value } = e.target;
    console.log(e.selectedRows);
    setSelectedValue(e.selectedRows);
    // console.log(value)
    // if (checked) {
    //   setSelectedValue(!checked);
    // }
    // else {
    //   selectedValues.splice(selectedValues.indexOf(value), 1)
    // }
    // this.setState({selectedValues})
  };

  //   renderCheckbox(data) {
  //     var checked = this.state.selectedValues.indexOf(data) != -1
  //   }

  const filteredData = driverData.filter((data) =>
    data.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    console.log(name, value);
    setFormErrors(RegisterValidater(inputs));
  };
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // setFormErrors(Validater(data));
    toastSuccess("Successfully created user");
    // redirect("/");
  };
  return (
      <div className="container-fluid">
        <CardHeader headerItems={headerButtons} title="UPDATE AND SEND GROUP MESSAGE AGAIN" />

        <Card className="mb-3 border-0 shadow-sm rounded-5">
          <Card.Body>
            <div className="form-wrapper">
              <Form
                noValidate
                className="needs-validation"
                onSubmit={handleSubmit}
              >
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="text" className="fw-600">
                    Message Title
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="messageHeading"
                      placeholder="Message Title"
                      onChange={handleChange}
                      value={data.groupName}
                      className="border-2"
                      required
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="fw-600"> Drivers group</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="status"
                          onChange={handleChange}
                          value={data.status}
                        >
                          <option>Select group</option>
                          <option value="1">Group 1</option>
                          <option value="2">Group 2</option>
                        </Form.Select>
                      </Form.Group>
                    </Row>

                <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="fw-600">Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="message"
                          placeholder="Message"
                          onChange={handleChange}
                          value={data.address}
                          style={{ height: "300px" }}
                        />
                      </Form.Group>
                    </Row>
                
                <Button
                  variant="primary"
                  type="submit"
                  className="shadow rounded-5"
                >
                  <FontAwesomeIcon icon={faCircleCheck} />Update and  Send Message
                </Button>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
  );
};

export default UpdateDriverMessage;
