import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastSuccess } from "../../Components/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { getUserByIdType, updateUserType } from "../../services/usertypeApi";
import { getjwt } from "../../services/authServices";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../usertypes", icon: faArrowLeft },
];

const Validater = (value) => {
  const errors = {};
  if (!value["user_type"]) {
    errors.user_type = "usertype is required";
  }
  return errors;
};

function UsertypeUpdate() {
  const { id } = useParams();
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [formErrors, setformErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setformErrors(Validater(inputs));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (data.user_type !== "") {
      data["token_data"] = JSON.parse(token);
      updateUserType(id, data)
        .then((res) => {
          if (res.status === 200) toastSuccess("usertype updated Successfully");
          navgate("../usertypes");
        })
        .catch((err) => {
          err.response.data.errors.map((errors) =>
            toastSuccess(`failed to updated : ${errors.error}`)
          );
        });
    } else {
      setformErrors(Validater(data));
    }
  };
  useEffect(() => {
    getUserByIdType(id).then((res) => setData(res.data));
  }, [id]);

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="UPDATE " />
      <Card className="mb-3 border-0 shadow-sm rounded-2">
        <Card.Body>
          <div className="form-wrapper">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Usertype</Form.Label>
                  <Form.Control
                    type="text"
                    name="user_type"
                    placeholder="Enter the User Type"
                    onChange={handleChange}
                    value={data.user_type}
                    className="border-2"
                    isInvalid={formErrors.user_type}
                  />
                  {formErrors.user_type && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.user_type}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5 mt-4"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Update usertype
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default UsertypeUpdate;
