import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { toastSuccess } from "./ToastNotification";
import { updateByTicketStatus } from "../services/tickets";
import { useNavigate } from "react-router-dom";

const CloseTicket = ({ ticketId, showMod, onClose }) => {
  const navgate = useNavigate()
  const [inputtext, setInputText] = useState(" ");
  const handleClose = (showMod) => onClose(!showMod);
  const handleInput = (e) => [setInputText(e.target.value)];
  

  const handleAdd = (showMod) => {
    const data = {};
    if (inputtext) {
      data["closed_note"] = inputtext;
      data["ticket_status"] = "closed";
      updateByTicketStatus(ticketId, data)
        .then((res) => {
          toastSuccess("Ticket closed sucessfully");
          navgate("../tickets")
        })
        .then((err) => console.log(err));
    }
  };

  return (
    <Modal show={showMod} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title> Note</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ width: "100wh" }}>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Ticket Close Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={inputtext}
              onChange={handleInput}
            />
          </Form.Group>
          <Form.Text id="textareaHelpBlock" muted>
            Your give the notes for close the ticket.
          </Form.Text>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={handleAdd} variant="primary">
          Submit and Close the ticket
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloseTicket;
