import React from "react";
import { DashCard } from "./DashCard";
import "./content.css";
import "./userCard/ContentComp.css";
import {
  faTruckMoving,
  faCheckToSlot,
  faTruckPlane,
  faTruckLoading
} from "@fortawesome/free-solid-svg-icons";
import Card from "react-bootstrap/Card";
import UserContent from "./userCard/UserContent";
import TruckData from "./userCard/TruckData";


const MainContent = () => {
  return (
     <div className="container-fluid">
      <Card className="mb-3 border-0 shadow-sm">
        <Card.Body>
          <div className="d-sm-flex align-items-center justify-content-between">
            <h1 className="h5 mb-0 text-gray-800 fw-bold">Dashboard</h1>
          </div>
        </Card.Body>
      </Card>
      <div className="row">
        <DashCard
          value="150"
          text="LOADS"
          icon={faTruckLoading}
          cssStyle="bg-info border-0"
        />
        <DashCard
          value="50"
          text=" ASSIGNED"
          icon={faCheckToSlot}
          cssStyle="bg-success border-0"
        />
        <DashCard
          value="45"
          text="COMPLETED"
          icon={faTruckMoving}
          cssStyle="bg-warning border-0"
        />
        <DashCard
          value="65"
          text="ONGOING"
          icon={faTruckPlane}
          cssStyle="bg-danger border-0"
        />
      </div>
      <div className="row">
        <UserContent />
        <TruckData />
      </div>
    </div>
   
  );
};

export default MainContent;
