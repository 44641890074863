

import React from "react";
import { Card, Col, Image, Table } from "react-bootstrap";
import CardHeader from "./../../Components/CardHeader";
import undraw_profile from "../../../assets/images/undraw_profile.svg";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to messages",
    buttonUrl: "../driversgroupmessage",
    icon: faArrowLeft,
  },
];
const DriverMessageDetail = () => {
  return (
      <div className="container-fluid">
        <CardHeader headerItems={headerButtons} title="Group Message Detail" />

        <div className="row">
         

          <Col sm={12} lg={12} xs={12}>
            <Card className="mb-3 p-2 border-0 shadow-sm">
              <Card.Body>
                <Table bordeless hover>
                  <tbody>
                    <tr>
                      <td>
                        <b>Message Title</b>
                      </td>
                      <td colSpan={2}>All Members</td>
                    </tr>

                    <tr>
                      <td>
                        <b>Message</b>
                      </td>
                      <td colSpan={2}>MEssage infrmations</td>
                    </tr>
                    <tr>
                      <td>
                        <b>Date</b>
                      </td>
                      <td colSpan={2}>15/10/2023 10:30</td>
                    </tr>
                    
                  </tbody>
                </Table>
              </Card.Body>
              
            </Card>
          </Col>

          
        </div>
      </div>
  );
};

export default DriverMessageDetail;
