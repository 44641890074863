import React, { useEffect, useState } from "react";
import { Card, Col, Image, Table, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import undraw_profile from "../../../src/assets/images/undraw_profile.svg";
import Badge from "react-bootstrap/Badge";
import CardHeader from "../Components/CardHeader";
import { faArrowLeft, faMessage } from "@fortawesome/free-solid-svg-icons";
// import pdf from "../../documents/dummy.pdf";
import { getByIdLoads } from "../services/loads";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to loads",
    buttonUrl: -1,
    icon: faArrowLeft,
  },
  {
    title: "User",
    buttonTitle: "Chat",
    buttonUrl: "../loads/onlive",
    icon: faMessage,
  },
];

const LoadDetailView = () => {
  const { id } = useParams();
  const [data,setData] = useState([])

  const getTruckTypeName = (type)=>{
    if( type === "S"){
      return "Standard"
    }
    if( type === "M"){
      return "Mega"
    }
    if( type === "V"){
      return "Vario"
    }
  }
  const getStatusName = (status)=>{
    if(status === "A"){
      return "Available"
    }
    if(status === "U"){
      return "Unavilable"
    }
    if(status === "H"){
      return "Holiday"
    }
  }

  useEffect(()=>{
    getByIdLoads(id).then(res=>setData(res.data))
  },[id])


  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title=" LOAD Details " />

      <Row>
        <Col sm={12} lg={5} xs={12}>
          <Card className="mb-3 p-4 border-0 shadow-sm">
            <Card.Body>
              <Card.Img
                style={{ width: "80px", borderRadius: "10px" }}
                src={data.driver && data.driver.image ? data.driver.image : undraw_profile}
                alt="Generic placeholder image"
                as={Image}
                fluid
              />
              <Card.Title className="mt-3 mb-2">
                Driver :<strong> {data.driver && data.driver.user.first_name + " " +data.driver.user.last_name}</strong>
              </Card.Title>
              <Card.Text>
                <Badge bg="danger p-2  shadow">Manager : {data.driver && data.driver.manager.first_name + " " +data.driver.manager.last_name}</Badge>
              </Card.Text>

              <Row className="mb-3">
                <Form.Group>
                  <Table striped bordered hover className="">
                    <tbody>
                      <tr>
                        <td className="fw-700">Truck</td>
                        <td>{data.driver && data.driver.truck.truck_name}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Platenumber</td>
                        <td>{data.driver && data.driver.truck.plate_number}</td>
                      </tr>

                      <tr>
                        <td className="fw-700">Truck Type</td>
                        <td>{data.driver && getTruckTypeName(data.driver.truck.truck_type)}</td>
                      </tr>

                      <tr>
                        <td className="fw-700">Truck Department</td>
                        <td>{data.driver && data.driver.truck.truck_department}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Weight</td>
                        <td>{data.driver && data.driver.truck.truck_weight}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Truck Height</td>
                        <td>{data.driver && data.driver.truck.truck_height}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Status</td>
                        <td>{data.driver && data.driver.truck.status}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table striped bordered hover className="">
                    <tbody>
                      <tr>
                        <td className="fw-700">Trailer</td>
                        <td>{data.driver && data.driver.trailer.trailer_name}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Platenumber</td>
                        <td>{data.driver && data.driver.trailer.plate_number}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Type</td>
                        <td>{data.driver && getTruckTypeName(data.driver.trailer.trailer_type)}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Working Load </td>
                        <td>{data.driver && data.driver.trailer.working_load}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Owm Weight</td>
                        <td>{data.driver && data.driver.trailer.own_weight}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">No Of Axis</td>
                        <td>{data.driver && data.driver.trailer.number_of_axis}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Max Weight authorized</td>
                        <td>{data.driver && data.driver.trailer.max_weight}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Length</td>
                        <td>{data.driver && data.driver.trailer.trailer_length}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Width</td>
                        <td>{data.driver && data.driver.trailer.trailer_width}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Height</td>
                        <td>{data.driver && data.driver.trailer.trailer_height}</td>
                      </tr>
                      <tr>
                        <td className="fw-700">Trailer Status</td>
                        <td>{data.driver && getStatusName(data.driver.trailer.status)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>

          <Card className="mb-3 mt-3 p-4 border-0 shadow-sm">
            <Card.Header className="text-start">
              <strong>Delivery notes</strong>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3 ">
                <p className="text-start">
                 {data.delivery_notes}
                </p>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} lg={7} xs={12}>
          <Card className="mb-3 p-2 border-0 shadow-sm">
            <Card.Body>
              <Table className="table-light" hover striped bordered="true">
                <tbody>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Location : From </b>
                    </td>
                    <td>{data.loading_location}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Loading Date</b>
                    </td>
                    <td>{data.loading_date}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Country </b>
                    </td>
                    <td>{data.loading_country}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Country Code</b>
                    </td>
                    <td>{data.loading_country_code}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Loading Reference</b>
                    </td>
                    <td>{data.loading_reference}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Status</b>
                    </td>
                    <td>
                      {" "}
                      <Badge bg="success p-2  shadow">{data.loading_status}</Badge>{" "}
                    </td>
                  </tr>
                  <tr>
                    <hr />
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Location : To </b>
                    </td>
                    <td>{data.unloading_location}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>UnLoading Date</b>
                    </td>
                    <td>{data.unloading_date}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>UnLoading Country</b>
                    </td>
                    <td>{data.unloading_country}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>UnLoading Country Code</b>
                    </td>
                    <td>{data.unloading_country_code}</td>
                  </tr>
                  <tr>
                    <hr />
                  </tr>
                  <td>
                    <h3 className="sec_loc_heading">Second Load Loaction</h3>
                  </td>

                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Location : From </b>
                    </td>
                    <td>{data.second_loading_location}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Loading Date</b>
                    </td>
                    <td>{data.second_loading_date}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Country </b>
                    </td>
                    <td>{data.second_loading_country}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Country Code</b>
                    </td>
                    <td>{data.second_loading_country_code}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Loading Reference</b>
                    </td>
                    <td>{data.second_loading_reference}</td>
                  </tr>
                  <tr>
                    <hr />
                  </tr>
                 
                  <td>
                    <h3 className="sec_loc_heading">Second UnLoad Loaction</h3>
                  </td>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Load Location : To </b>
                    </td>
                    <td>{data.second_unloading_location}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>UnLoading Date</b>
                    </td>
                    <td>{data.second_unloading_date}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>UnLoading Country</b>
                    </td>
                    <td>{data.second_unloading_country}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>UnLoading Country Code</b>
                    </td>
                    <td>{data.second_unloading_country_code}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Unload Reference</b>
                    </td>
                    <td>{data.second_unloading_reference}</td>
                  </tr>
                  <td>
                    <hr />
                  </td>
                 
                </tbody>
              </Table>

              <Table bordeless="true" hover className="mt-4">
                <tbody>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Goods type </b>
                    </td>
                    <td>{data.goods_type}</td>
                  </tr>
                  <tr className="text-start">
                    <td className="pl-3">
                      <b>Identification Position</b>
                    </td>
                    <td>{data.identification_position} </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Card className="mb-3 mt-3 p-4 border-0 shadow-sm">
            <Card.Header className="text-start">
              <strong>Attachments</strong>
            </Card.Header>
            <Card.Body>
              {/* <Row className="mb-3 text-start">
                <a href={pdf}>attachmentfile</a>
                <a href={undraw_profile} className="mt-2">
                  attachmentfile8890
                </a>
              </Row> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LoadDetailView;
