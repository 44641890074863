import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getUsers = async () => {
  return await http.get(APIURL + "/user/list/");
};

export const getUserById = async (id) => {
  return await http.get(APIURL + `/user/${id}/`);
};

export const deleteUser = async (id) => {
  return await http.delete(APIURL + `/user/delete/${id}/`);
};
export const createUser = async (data) => {
  return await http.post(APIURL + "/user/create/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const updateUser = async (id, data) => {
  return await http.update(APIURL + `/user/update/${id}/`, data);
};

export const getManager = async () => {
  return await http.get(APIURL + "/manager/");
};
export const getDriver = async () => {
  return await http.get(APIURL + "/Driver/list/");
};

export const getDriversByManagerId = async (manage_id) => {
  return await http.get(APIURL + `/Driver/list/?manager_id=${manage_id}`);
};

export const createDriver = async (data) => {
  return await http.post(APIURL + "/Driver/create/", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getDriverDetails = async (id) => {
  return await http.get(APIURL + `/Driver/${id}/`);
};
export const deleteDriver = async (id) => {
  return await http.delete(APIURL + `/Driver/delete/${id}/`);
};
export const UpdateDriver = async (id, data) => {
  return await http.update(APIURL + `/Driver/update/${id}/`, data);
};
export const UpdateDriverManger = async (id, data) => {
  return await http.put(APIURL + `/Driver/manager/update/${id}/`, data);
};
