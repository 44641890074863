import React, { useState, useEffect } from "react";
import { Card, Col, Table } from "react-bootstrap";
import CardHeader from "./../../Components/CardHeader";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getDriverGroupById } from "../../services/DriverGroups";
import { useParams } from "react-router-dom";

const headerButtons = [
  {
    title: "User",
    buttonTitle: "Back to groups",
    buttonUrl: "../driversgroup",
    icon: faArrowLeft,
  },
];

const DriverGroupDetails = () => {
  const { id } = useParams();
  const [driverGroup, setDriverGroup] = useState([]);

  useEffect(() => {
    getDriverGroupById(id).then((res) => setDriverGroup(res.data));
  }, [id]);
  
  const drivers = driverGroup.drivers;

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Group Detail" />

      <div className="row">
        <Col sm={12} lg={12} xs={12}>
          <Card className="mb-3 p-2 border-0 shadow-sm">
            <Card.Body>
              <Table bordeless hover>
                <tbody>
                  <tr>
                    <td>
                      <b>Group Name</b>
                    </td>
                    <td colSpan={2}>{driverGroup.title}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Total members</b>
                    </td>
                    <td colSpan={2}>
                      {driverGroup.drivers && driverGroup.drivers.length}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total messages sent</b>
                    </td>
                    <td colSpan={2}>0</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} lg={12} xs={12}>
          <Card className="mb-3 p-2 border-0 shadow-sm">
            <Card.Header className="text-start">
              <h5>
                <b>Drivers</b>(
                {driverGroup.drivers && driverGroup.drivers.length})
              </h5>
            </Card.Header>
            <Card.Body>
              <Table striped bordered hover className="">
                <tbody>
                  {drivers &&
                    drivers.map((item) => (
                      <tr>
                        <td className="fw-700">{drivers.indexOf(item) + 1}</td>
                        <td>
                          {item.user.first_name + " " + item.user.last_name}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </div>
  );
};

export default DriverGroupDetails;
