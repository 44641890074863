import React, { createContext,useState } from "react";

export const NavBarContext = createContext();

function NavBarComp(props) {
  const [nav, setNav] = useState([]);

  return (
    <NavBarContext.Provider value={[nav, setNav]}>
      {props.children}
    </NavBarContext.Provider>
  );
}
export default NavBarComp;
