import React,{useEffect,useState} from "react";
import { Card } from "react-bootstrap";
import Table from "../Components/Tables";
import CardHeader from "../Components/CardHeader";
import { deleteLoads, getByLoadStatus, getLoads } from "../services/loads";
import TableActionButton from "../Components/TableActionButton";


const headerButtons = [];
// const conditionalRowStyles = [
//     {
//       when: (row) => row.Status.toLowerCase() === "transit",
//       style: {
//         backgroundColor: "rgba(238,214,204)",
//         color: "black",
//         "&:hover": {
//           cursor: "pointer",
//         },
//       },
//     },
//   ];

const TransitLoad = () => {
  const [data, setData] = useState([]);

  const getloaddata = () => {
    getByLoadStatus("transit").then((res) => setData(res.data));
  };
  const TableColumn = [
    {
      name: "Truck Plate Nr",
      selector: (row) => row.driver.truck.plate_number,
      sortable: true,
    },
    {
      name: "Trailer Plate Nr",
      selector: (row) => row.driver.trailer.plate_number,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.loading_status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.loading_status.toLowerCase() === "transit",
          style: {
            backgroundColor: "rgba(238,214,204)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },
    {
      name: "Loading Country",
      selector: (row) => row.loading_country,
      sortable: true,
    },
    {
      name: "Loading Country Code",
      selector: (row) => row.loading_country_code,
      sortable: true,
    },

    {
      name: "LoadingPoint",
      selector: (row) => row.loading_location,
      sortable: true,
    },
    {
      name: "LoadingDate",
      selector: (row) => row.loading_date,
      sortable: true,
    },
    {
      name: "Identif Number",
      selector: (row) => row.identification_position,
      sortable: true,
    },
    {
      name: "Loading Reference",
      selector: (row) => row.loading_reference,
      sortable: true,
    },
    {
      name: "Unloading Country",
      selector: (row) => row.unloading_country,
      sortable: true,
    },
    {
      name: "Unloading County Code",
      selector: (row) => row.unloading_country_code,
      sortable: true,
    },
    {
      name: "Unloading Point",
      selector: (row) => row.unloading_location,
      sortable: true,
    },
    {
      name: "UnloadingDate",
      selector: (row) => row.unloading_date,
      sortable: true,
    },
    {
      name: "DeliveryNotes",
      selector: (row) => row.delivery_notes,
      sortable: true,
    },

    {
      name: "TruckType",
      selector: (row) =>{
        if( row.driver.truck.truck_type === "S"){
          return "Standard"
        }
        if( row.driver.truck.truck_type === "M"){
          return "Mega"
        }
        if( row.driver.truck.truck_type === "V"){
          return "Vario"
        }
       },
      sortable: true,
    },
    {
      name: "Department",
      selector: (row) => row.driver.truck.truck_department,
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) => row.driver.user.first_name,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row.driver.manager.first_name,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/load/${row.id}`}
          UpdatePath={`/dashboard/update/load/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteLoads}
          refreshdata={getloaddata}
        />
      ),
    },
  ];

  useEffect(() => {
    getloaddata();
  }, []);
  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Transit Loads" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table
              columns={TableColumn}
              row={data}
              // conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TransitLoad;
