import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import "./form.css";
import CardHeader from "../../Components/CardHeader";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleCheck,
  faTruckArrowRight,
  faTruckLoading,
  faUser,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import AddLocationModal from "../../Components/AddLocationModal";
import { addLoads } from "../../services/loads";
import { useNavigate } from "react-router-dom";
import { getDriver } from "../../services/users";
import AddUnLoadLocationModal from "../../Components/AddUnloadlocations";
import { getDriverDetails } from "./../../services/users";

const headerButtons = [
  { buttonTitle: "Back to loads", buttonUrl: "../loads", icon: faArrowLeft },
];

const INITIAL_DATA = {
  loading_location: "",
  loading_date: "",
  loading_country: "",
  loading_country_code: "",
  loading_reference: "",
  unloading_location: "",
  unloading_date: "",
  unloading_country: "",
  unloading_country_code: "",
  unloading_reference: "",
  delivery_notes: "",
  goods_type: "",
  identification_position: "",
  driver: "",
  loading_status: "",
};

const LoadsValidator = (value) => {
  let errors = {};
  if (!value["loading_location"]) {
    errors.loading_location = "Loading Location is required";
  }
  if (!value["loading_country"]) {
    errors.loading_country = "Loading Country is required";
  }
  if (!value["loading_country_code"]) {
    errors.loading_country_code = "Loading Country  Code is required";
  }
  if (!value["loading_reference"]) {
    errors.loading_reference = "Loading Reference is required";
  }
  if (!value["unloading_location"]) {
    errors.unloading_location = "Unloading Location is required";
  }
  if (!value["unloading_country"]) {
    errors.unloading_country = "Unloading Country is required";
  }
  if (!value["unloading_reference"]) {
    errors.unloading_reference = "Unloading Reference is required";
  }
  if (!value["driver"]) {
    errors.driver = "Driver is required";
  }
  if (!value["delivery_notes"]) {
    errors.delivery_notes = "Delivery Notes is required";
  }
  if (!value["goods_type"]) {
    errors.goods_type = "Goods Type is required";
  }
  if (!value["identification_position"]) {
    errors.identification_position = "Identification Position is required";
  }
  if (!value["loading_status"]) {
    errors.loading_status = "Loading Status is required";
  }
  return errors;
};

const LoadForm = () => {
  const navgate = useNavigate();
  const [data, setData] = useState(INITIAL_DATA);
  const [date1, setdate] = useState(new Date());
  const [date2, setdate2] = useState(new Date());
  const [driver, setDriver] = useState([]);
  const [driverdetials, setDriverDetials] = useState();
  const [secondloaddata, setSecLoadData] = useState();
  const [secondunloaddata, setSecUnLoadData] = useState();
  const [modalshow, setModalShow] = useState(false);
  const [modalshowtwo, setModalShowTwo] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    getDriver().then((res) => setDriver(res.data));
  }, []);

  const handleClose = (value) => {
    setModalShow(value);
  };

  const handleClosetwo = (value) => {
    setModalShowTwo(value);
  };

  const secLoadData = (data) => {
    setSecLoadData(data);
  };

  const secUnLoadData = (data) => {
    setSecUnLoadData(data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setFormErrors(LoadsValidator(inputs));
    if (inputs.driver) {
      getDriverDetails(inputs.driver).then((res) => setDriverDetials(res.data));
    }
  };

  const formatDate = (date) => {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = "" + d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  if (secondloaddata) {
    data["second_loading_country"] = secondloaddata["second_loading_country"];
    data["second_loading_location"] = secondloaddata["second_loading_location"];
    data["second_loading_country_code"] =
      secondloaddata["second_loading_country_code"];
    data["second_loading_reference"] =
      secondloaddata["second_loading_reference"];
    data["second_loading_date"] = secondloaddata["second_loading_date"];
  }

  if (secondunloaddata) {
    data["second_unloading_country"] =
      secondunloaddata["second_unloading_country"];
    data["second_unloading_location"] =
      secondunloaddata["second_unloading_location"];
    data["second_unloading_country_code"] =
      secondunloaddata["second_unloading_country_code"];
    data["second_unloading_reference"] =
      secondunloaddata["second_unloading_reference"];
    data["second_unloading_date"] = secondunloaddata["second_unloading_date"];
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (date1 !== undefined) {
      console.log(date1)
      const dateOne = formatDate(date1._d || date1);
      console.log(dateOne)
      data["loading_date"] = dateOne;
    }
    if (date2 !== undefined) {
      const datatwo = formatDate(date2._d || date2);
      data["unloading_date"] = datatwo;
    }
    if (
      data.loading_location !== "" &&
      data.loading_country !== "" &&
      data.loading_country_code !== "" &&
      data.loading_reference !== "" &&
      data.unloading_location !== "" &&
      data.unloading_country !== "" &&
      data.unloading_country_code !== "" &&
      data.unloading_reference !== "" &&
      data.loading_status !== "" &&
      data.identification_position !== "" &&
      data.goods_type !== "" &&
      data.driver !== "" &&
      data.delivery_notes !== ""
    ) {
      addLoads(data)
        .then((res) => {
          toastSuccess("succesfully created loads");
          navgate("../loads");
        })
        .catch((err) => {
          let errObj = err.response.data;
          toastError(errObj.error);
        });
    } else {
      setFormErrors(LoadsValidator(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Add Load" />
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        {" "}
                        <p className="h6 fw-700 mb-3 text-danger">
                          {" "}
                          <FontAwesomeIcon icon={faTruckLoading} /> From
                        </p>
                      </Form.Label>

                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">
                          Loading Location
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="loading_location"
                          onChange={handleChange}
                          value={data.loading_location}
                          className="border-2"
                          placeholder="Enter the location"
                          isInvalid={formErrors.loading_location}
                        />
                        {formErrors.loading_location && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.loading_location}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="fw-600">Loading Date</Form.Label>
                        <Datetime
                          onChange={(date) => setdate(date)}
                          name="loading_date"
                          value={date1}
                          timeFormat={false}
                        />
                      </Form.Group>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600">
                        Loading Country
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="loading_country"
                        onChange={handleChange}
                        value={data.loading_country}
                        className="border-2"
                        placeholder="Enter the Country"
                        isInvalid={formErrors.loading_country}
                      />
                      {formErrors.loading_country && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.loading_country}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="fw-600">
                        Loading Country Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="loading_country_code"
                        onChange={handleChange}
                        value={data.loading_country_code}
                        className="border-2"
                        placeholder="Enter the Country Code"
                        isInvalid={formErrors.loading_country_code}
                      />
                      {formErrors.loading_country_code && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.loading_country_code}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600">
                        Loading Reference{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="loading_reference"
                        onChange={handleChange}
                        value={data.loading_reference}
                        className="border-2"
                        placeholder="Enter the Reference"
                        isInvalid={formErrors.loading_location}
                      />
                      {formErrors.loading_reference && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.loading_reference}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Button onClick={() => setModalShow(true)}>
                      Add Second load Location
                    </Button>
                    {modalshow ? (
                      <AddLocationModal
                        title="loaded"
                        showMod={modalshow}
                        onClose={handleClose}
                        onSubmit={secLoadData}
                      />
                    ) : (
                      <AddLocationModal showMod={false} />
                    )}
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        {" "}
                        <p className="h6 fw-700  mb-3 text-danger">
                          {" "}
                          <FontAwesomeIcon icon={faTruckArrowRight} /> To{" "}
                        </p>{" "}
                      </Form.Label>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">
                          UnLoading Location
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="unloading_location"
                          onChange={handleChange}
                          value={data.unloading_location}
                          className="border-2"
                          placeholder="Enter the location"
                          isInvalid={formErrors.unloading_location}
                        />
                        {formErrors.unloading_location && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.unloading_location}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="fw-600">
                          UnLoading Date
                        </Form.Label>
                        <Datetime
                          onChange={(date) => setdate2(date)}
                          name="unloading_date"
                          value={date2}
                          timeFormat={false}
                        />
                      </Form.Group>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600">
                        UnLoading Country
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="unloading_country"
                        onChange={handleChange}
                        value={data.unloading_country}
                        className="border-2"
                        placeholder="Enter the Country"
                        isInvalid={formErrors.unloading_country}
                      />
                      {formErrors.unloading_country && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.unloading_country}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600">
                        UnLoading Country Code
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="unloading_country_code"
                        onChange={handleChange}
                        value={data.unloading_country_code}
                        className="border-2"
                        placeholder="Enter the Country Code"
                        isInvalid={formErrors.unloading_country_code}
                      />
                      {formErrors.unloading_country_code && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.unloading_country_code}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="fw-600">
                        UnLoading Reference
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="unloading_reference"
                        onChange={handleChange}
                        value={data.unloading_reference}
                        className="border-2"
                        placeholder="Enter the Reference"
                        isInvalid={formErrors.unloading_reference}
                      />
                      {formErrors.unloading_reference && (
                        <Form.Control.Feedback type="invalid">
                          {formErrors.unloading_reference}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    <Button onClick={() => setModalShowTwo(true)}>
                      Add Second UnLoad Location
                    </Button>
                    {modalshowtwo ? (
                      <AddUnLoadLocationModal
                        title="Unloaded"
                        showMod={modalshowtwo}
                        onClose={handleClosetwo}
                        onSubmit={secUnLoadData}
                      />
                    ) : (
                      <AddUnLoadLocationModal showMod={false} />
                    )}
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        {" "}
                        <p className="h6 fw-700 mb-3 text-danger">
                          {" "}
                          <FontAwesomeIcon icon={faUser} /> Driver
                        </p>
                      </Form.Label>

                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">Driver</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="driver"
                          onChange={handleChange}
                          value={data.driver}
                          isInvalid={formErrors.driver}
                        >
                          <option>Select the driver</option>
                          {driver.map((item, idx) => (
                            <option key={idx} value={item.id}>
                              {item.user.first_name + " "+item.user.last_name}
                            </option>
                          ))}
                        </Form.Select>
                        {formErrors.driver && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.driver}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Row className="mb-3">
                        <Form.Group>
                          <Table striped bordered hover className="mt-4">
                            <tbody>
                              <tr>
                                <td className="fw-700">Truck</td>
                                <td>
                                  {driverdetials &&
                                    driverdetials.truck.truck_name}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-700">Truck Platenumber</td>
                                <td>
                                  {driverdetials &&
                                    driverdetials.truck.plate_number}
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <Table striped bordered hover className="mt-5">
                            <tbody>
                              <tr>
                                <td className="fw-700">Trailer</td>
                                <td>
                                  {driverdetials &&
                                    driverdetials.trailer.trailer_name}
                                </td>
                              </tr>
                              <tr>
                                <td className="fw-700">Trailer Platenumber</td>
                                <td>
                                  {driverdetials &&
                                    driverdetials.trailer.plate_number}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Form.Group>
                      </Row>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={6} sm={6} xs={6} md={6}>
            <Card className="mb-3 border-0 shadow-sm rounded-2">
              <Card.Body>
                <div className="form-wrapper">
                  <Row className="mb-3">
                    <Form.Group>
                      <Form.Label>
                        {" "}
                        <p className="h6 fw-700 mb-3 text-danger">
                          {" "}
                          <FontAwesomeIcon icon={faStickyNote} /> Additional
                          information
                        </p>
                      </Form.Label>

                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">
                          Delivery notes
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          name="delivery_notes"
                          onChange={handleChange}
                          value={data.delivery_notes}
                          style={{ height: "100px" }}
                          isInvalid={formErrors.delivery_notes}
                        />
                        {formErrors.delivery_notes && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.delivery_notes}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">Goods Type</Form.Label>
                        <Form.Control
                          type="text"
                          name="goods_type"
                          onChange={handleChange}
                          value={data.goods_type}
                          isInvalid={formErrors.goods_type}
                        />
                        {formErrors.goods_type && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.goods_type}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">
                          Identification Position
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="identification_position"
                          onChange={handleChange}
                          value={data.identification_position}
                          isInvalid={formErrors.identification_position}
                        />
                        {formErrors.identification_position && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.identification_position}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="fw-600">Load Status</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="loading_status"
                          onChange={handleChange}
                          value={data.loading_status}
                          isInvalid={formErrors.loading_status}
                        >
                          <option>Select </option>
                          <option value="pending1">Pending 1</option>
                          <option value="pending2">Pending 2</option>
                          <option value="loading">Loading</option>
                          <option value="closed">Closed</option>
                          <option value="complete">Complete</option>
                          <option value="transit">Transit</option>
                        </Form.Select>
                        {formErrors.loading_status && (
                          <Form.Control.Feedback type="invalid">
                            {formErrors.loading_status}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Form.Group>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={12} sm={12} xs={12} md={12}>
            <Card className="mb-3 border-0 shadow-sm rounded-2 p-0 m-0 ">
              <Card.Body className="text-start">
                <Button
                  variant="primary"
                  type="submit"
                  className="rounded-5 shadow-sm w-25"
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> Create Load
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LoadForm;
