import React, { useState } from "react";
import { Card, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Datetime from "react-datetime";

const INITIAL_DATA = {
  second_loading_location: "",
  second_loading_country: "",
  second_loading_country_code: "",
  second_loading_reference: "",
};

const AddLocationModal = ({ title, showMod, onClose, onSubmit }) => {
  const [data, setData] = useState(INITIAL_DATA);
  const [date, setDate] = useState(new Date());
  const handleClose = (showMod) => onClose(!showMod);
  const formatDate = (date) => {
    let d = new Date(date);
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = "" + d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const format_date = formatDate(date);
  data["second_loading_date"] = format_date;
  const handleAdd = (showMod) => {
    if (data) {
      onSubmit(data);
      onClose(!showMod);
    }
  };

  return (
    <Modal className="modal-xl" show={showMod} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Second {title} Location</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ width: "100wh" }}>
        <Card className="mb-3 border-0 shadow-sm rounded-2">
          <Card.Body>
            <div className="form-wrapper">
              <Row className="mb-3">
                <Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="fw-600">{title} Location</Form.Label>
                    <Form.Control
                      type="text"
                      name="second_loading_location"
                      onChange={handleChange}
                      value={data.second_loading_location}
                      className="border-2"
                      placeholder="Enter the location"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="fw-600">{title} Date</Form.Label>
                    <Datetime
                      name="second_loading_date"
                      value={date}
                      onChange={(date) => setDate(date)}
                      timeFormat={false}
                    />
                  </Form.Group>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-600">{title} Country</Form.Label>
                  <Form.Control
                    type="text"
                    name="second_loading_country"
                    onChange={handleChange}
                    value={data.second_loading_country}
                    className="border-2"
                    placeholder="Enter the Country"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-600">
                    {title} Country Code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="second_loading_country_code"
                    onChange={handleChange}
                    value={data.second_loading_country_code}
                    className="border-2"
                    placeholder="Enter the Country Code"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="fw-600">{title} Reference</Form.Label>
                  <Form.Control
                    type="text"
                    name="second_loading_reference"
                    onChange={handleChange}
                    value={data.second_loading_reference}
                    className="border-2"
                    placeholder="Enter the Reference"
                  />
                </Form.Group>
              </Row>
            </div>
          </Card.Body>
        </Card>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={handleClose} variant="secondary">
          Close
        </Button>
        <Button onClick={handleAdd} variant="primary">
          Add Second {title} Location
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLocationModal;
