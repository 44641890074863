import React from "react";
import UserTableBody from "./UserTableBody";
import undraw_profile from "../../../../assets/images/undraw_profile.svg";


const UserContent = () => {
  return (
    <div className="appointment-sec box-col col col-xxl-4 ">
      <div className="appointment">
        <div className="card-recent shadow-sm">
          <div className="card-no-border card-header p-3">
            <div className="header-top">
              <h5 className="m-0 fw-bold">Recent Loadings</h5>
            </div>
          </div>
          <div className="pt-0 card-body">
            <div className="appointment-table">
              <div className="table-responsive">
                <table className="table ">
                  <UserTableBody
                    img={undraw_profile}
                    name="Don Joe"
                    time="29th March 2023"
                    timeData = "10:30 am"
                  />
                  <UserTableBody
                    img={undraw_profile}
                    name="Don Joe"
                    time="29th March 2023"
                    timeData = "10:30 am"
                  />
                  <UserTableBody
                    img={undraw_profile}
                    name="Don Joe"
                    time="29th March 2023"
                    timeData = "10:30 am"
                  />
                  <UserTableBody
                    img={undraw_profile}
                    name="Don Joe"
                    time="29th March 2023"
                    timeData = "10:30 am"
                  />
                  
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserContent;
