import React, { useState } from "react";
import "./sidebar.css";
import SideBarItems from "./SidebarItems";
import name from "../../../assets/images/name.svg";
import {
  faTruck,
  faSignOutAlt,
  faColumns,
  faUserAlt,
  faTruckLoading,
  faCog,
  faTicket,
  faTruckFront,
  faTruckFast,
  faMessage,
  faClosedCaptioning,
  faUserGroup,
  faCheckDouble,
  faArrowUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SideBar = () => {
  const [activeKeys, setActiveKeys] = useState([""]);
  const handleSelect = (eventKey) => setActiveKeys(eventKey);

  return (
    <ul className="sidebar ">
      <div className="logo">
        <img src={name} alt="" width="130px" />
      </div>

      <div className="Menu">
        <SideBarItems title="Dashboard" to="" icon={faColumns} />

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faUserAlt} />
                <span className="menuItemtitle">Users</span>
              </li>
            </Accordion.Header>
            <Accordion.Body className="items">
              <SideBarItems title="Users" to="users" icon={faUserAlt} popIcon={faArrowUpRightFromSquare} />
              <SideBarItems title="Drivers" to="drivers" icon={faTruck} popIcon={faArrowUpRightFromSquare} />
              <SideBarItems
                title="Group"
                to="driversgroup"
                icon={faUserGroup}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Messages"
                to="driversgroupmessage"
                icon={faMessage}
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        {/* <SideBarItems title="Loads" to="loads" icon={faTruckLoading} /> */}

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faTruckLoading} />
                <span className="menuItemtitle">Loads</span>
              </li>
            </Accordion.Header>
            <Accordion.Body className="loadsitems">
              <SideBarItems
                title="All loads"
                to="loads"
                icon={faTruckLoading}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Pending 1"
                to="pendingloads"
                icon={faTruckFront}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Pending 2"
                to="pendingloadstwo"
                icon={faTruckFront}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Loading"
                to="loadingloads"
                icon={faTruckFast}
                popIcon={faArrowUpRightFromSquare}
              />

              <SideBarItems
                title="Closed"
                to="closedloads"
                icon={faTruckFast}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Transit"
                to="transitload"
                icon={faTruckFast}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Complete"
                to="completedloads"
                icon={faCheckDouble}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="live"
                to="loads/onlive"
                icon={faMessage}
                popIcon={faArrowUpRightFromSquare}
              />

              <SideBarItems
                title="Prev Chat"
                to="loads/onclosed"
                icon={faClosedCaptioning}
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion activeKey={activeKeys} onSelect={handleSelect}>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <li className="menuitem bar">
                <FontAwesomeIcon icon={faTicket} />
                <span className="menuItemtitle">Tickets</span>
              </li>
            </Accordion.Header>
            <Accordion.Body className="items">
              <SideBarItems title="All" to="tickets" icon={faTicket}
              popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems title="Open" to="tickets/open" icon={faTicket} 
              popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Pending"
                to="tickets/pending"
                icon={faTicket}
                popIcon={faArrowUpRightFromSquare}
              />
              <SideBarItems
                title="Closed"
                to="tickets/closed"
                icon={faTicket}
                popIcon={faArrowUpRightFromSquare}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <div className="last-child">
          <Accordion
            activeKey={activeKeys}
            onSelect={handleSelect}
            className="settingsAccordion"
          >
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <li className="menuitem bar">
                  <FontAwesomeIcon icon={faCog} />
                  <span className="menuItemtitle">Settings</span>
                </li>
              </Accordion.Header>
              <Accordion.Body>
                <SideBarItems title="Usertypes" to="usertypes" icon="" 
                popIcon={faArrowUpRightFromSquare}
                
                />
                <SideBarItems title="Trucks" to="trucks" icon=""
                popIcon={faArrowUpRightFromSquare}
                
                />
                <SideBarItems title="Trailers" to="trailers" icon="" 
                popIcon={faArrowUpRightFromSquare}
                
                />
                <SideBarItems title="Tickettypes" to="tickettypes" icon=""
                popIcon={faArrowUpRightFromSquare}
                
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <SideBarItems  title="logout" to="/" icon={faSignOutAlt} />
        </div>
      </div>
    </ul>
  );
};

export default SideBar;
