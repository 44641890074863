import { faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import TableActionButton from "../Components/TableActionButton";
import Table from "../Components/Tables";
import CardHeader from "./../Components/CardHeader";
import "./tickects.css";
import { deleteTicket, getTickets } from "../services/tickets";

const headerButtons = [
  {
    buttonTitle: "Add Ticket",
    buttonUrl: "/dashboard/add/tickets",
    icon: faTicketAlt,
  },
];

const Tickets = () => {
  const [data, setData] = useState([]);


  const refreshTicket = () => {
    getTickets().then((res) => setData(res.data));
  };

  useEffect(() => {
    refreshTicket();
  }, []);
  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row.manager.first_name + " " + row.manager.last_name,
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) =>
        row.driver.user.first_name + " " + row.driver.user.last_name,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.ticket_type.ticket_type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.ticket_status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.ticket_status.toLowerCase() === "open",
          style: {
            backgroundColor: "rgba(255, 184, 177, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.ticket_status.toLowerCase() === "pending",
          style: {
            backgroundColor: "rgba(236, 161, 56, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
        {
          when: (row) => row.ticket_status.toLowerCase() === "closed",
          style: {
            backgroundColor: "rgba(115, 236, 174, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },

    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/tickets/${row.id}`}
          UpdatePath={`/dashboard/update/tickets/${row.id}`}
          deleteId={row.id}
          refreshdata={refreshTicket}
          deleteFun={deleteTicket}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="All Tickets" />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table
              columns={columns}
              row={data}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Tickets;
