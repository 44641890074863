import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const getTicketTypes = async () => {
  return await http.get(APIURL + "/ticket_type/");
};
export const addTicketTypes = async (data) => {
  return await http.post(APIURL + "/ticket_type/add/", data);
};
export const getTicketTypeById = async (id) => {
  return await http.get(APIURL + `/ticket_type/${id}/`);
};

export const deleteTicketType = async (id) => {
  return await http.delete(APIURL + `/ticket_type/delete/${id}/`);
};

export const updateTicketType = async (id, data) => {
  return await http.update(APIURL + `/ticket_type/update/${id}/`, data);
};
