import React from "react";
import { Link, useResolvedPath, useMatch } from "react-router-dom";

function NavTopItems({ nav, onClose }) {
  const resolvedPath = useResolvedPath(nav.url);
  const isactive = useMatch({ path: resolvedPath.pathname, end: true });
  return (
    <>
      {nav ? (
        <div
          className={
            isactive
              ? "topbar-item active rounded-pill p-3 d-flex justify-content-space-between align-item-center"
              : "topbar-item rounded-pill p-3 d-flex justify-content-space-between align-item-center"
          }
        >
          <div>
            <Link className="top-bar" to={nav.url}>
              <span className="menuItemtitle">{nav.title}</span>
            </Link>
          </div>
          <div className="mx-1">
              <p className="closebtn " onClick={(e) => onClose(nav.id)}>
            <Link className="top-bar" to={-2}>
                x
            </Link>
              </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NavTopItems;
