function LoginValidator(values) {
  let errors = {};
  const emailPattern = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!emailPattern.test(values.email)) {
    errors.email = "Enter a valid Email Address";
  } 
  
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 6) {
    errors.password = "Password length must be atleast 6 characters";
  } 
  return errors;
}
export default LoginValidator;
