import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import { NavBarContext } from "../../context/NavBarContext";
import { useNavigate } from "react-router-dom";

const SideBarItems = ({ title, to, icon, popIcon }) => {
  const navigate = useNavigate();
  const [tab, setTab] = useContext(NavBarContext);
  let count = Math.random();

  const handleClick = () => {
    const temp = [...tab];
    const data = {
      id: count,
      title: title,
      url: to,
    };
    temp.push(data);
    setTab(temp);
    localStorage.setItem("tabs", JSON.stringify(temp));
    navigate(to);
  };

  const resolvedPath = useResolvedPath(to);
  const isactive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li
      className={
        isactive
          ? "menuitem p-3 d-flex justify-content-space-between align-item-center active"
          : "menuitem p-3 d-flex justify-content-space-between align-item-center"
      }
    >
      <div>
        <Link className="bar" to={to}>
          <FontAwesomeIcon icon={icon} />
          <span className="menuItemtitle ">{title}</span>
        </Link>
      </div>

      <div className=" popbtn ">
        {popIcon ? (
          <FontAwesomeIcon onClick={handleClick} icon={popIcon} />
        ) : (
          ""
        )}
      </div>
    </li>
  );
};

export default SideBarItems;
