import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "./../../Components/CardHeader";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import {
  getTicketById,
  updataTicket,
  updateTicket,
} from "../../services/tickets";
import { getDriver, getManager } from "../../services/users";
import { getTicketTypes } from "../../services/ticketType";
import { getjwt } from "../../services/authServices";

const headerButtons = [
  {
    buttonTitle: "Back to tickets",
    buttonUrl: "../tickets",
    icon: faArrowLeft,
  },
];

const TicketUpdateForm = () => {
  const { id } = useParams("");
  const navgate = useNavigate();
  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [manager, setManager] = useState([]);
  const [ticketType, setTicketType] = useState([]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  useEffect(() => {
    getTicketById(id).then((res) => setData(res.data));
    getDriver().then((res) => setDrivers(res.data));
    getManager().then((res) => setManager(res.data));
    getTicketTypes().then((res) => setTicketType(res.data));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    data["token_data"] = JSON.parse(token);
    data["driver"] = data.driver.id || data.driver;
    data["manager"] = data.manager.id || data.manager;
    data["ticket_type"] = data.ticket_type.id || data.ticket_type;
    updateTicket(id, data)
      .then((res) => {
        toastSuccess("succesfully Updated Tickets");
        navgate("../tickets");
      })
      .catch((err) => {
        let errObj = err.response.data;
        if (errObj) {
          errObj.errors.map((err) => toastError(err.error));
        }
      });
  };
  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="UPDATE Tickets" />

      <Card className="mb-3 border-0 shadow-sm rounded-5">
        <Card.Body>
          <div className="form-wrapper">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="First name"
                    onChange={handleChange}
                    value={data.title}
                    className="border-2"
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Ticket Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="ticket_status"
                    onChange={handleChange}
                    value={data.ticket_status}
                  >
                    <option value="open">open</option>
                    <option value="pending">pending</option>
                    <option value="closed">closed</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Driver</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="driver"
                    onChange={handleChange}
                    value={data.driver && data.driver.id}
                  >
                    {drivers.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.user.first_name + " " + item.user.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Manager</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="manager"
                    onChange={handleChange}
                    value={data.manager && data.manager.id}
                  >
                    {manager.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.first_name + " " + item.last_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Ticket Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="status"
                    onChange={handleChange}
                    value={data.ticket_type && data.ticket_type.id}
                  >
                    {ticketType.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.ticket_type}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label className="fw-600">Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="note"
                    placeholder="Add Note"
                    onChange={handleChange}
                    value={data.note}
                    style={{ height: "100px" }}
                  />
                </Form.Group>
              </Row>
              <hr></hr>
              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Update Ticket
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TicketUpdateForm;
