import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastSuccess } from "../../Components/ToastNotification";
import { useNavigate, useParams } from "react-router-dom";
import CardHeader from "../../Components/CardHeader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { getTrailersByid, updateTrailers } from "../../services/trailer";
import { getjwt } from "../../services/authServices";

const headerButtons = [
  { buttonTitle: "Back", buttonUrl: "../trailers", icon: faArrowLeft },
];

const Validater = (value) => {
  const errors = {};
  if (!value["trailer_name"]) {
    errors.trailer_name = "please add the trailer name";
  }
  if (!value["trailer_width"]) {
    errors.trailer_width = "please add trailer width";
  }
  if (!value["trailer_length"]) {
    errors.trailer_length = "please add trailer length";
  }
  if (!value["trailer_type"]) {
    errors.trailer_type = "please add type";
  }
  if (!value["trailer_height"]) {
    errors.trailer_height = "please add height";
  }
  if (!value["plate_number"]) {
    errors.plate_number = "please add plateNumber";
  }
  if (!value["working_load"]) {
    errors.working_load = "please add working_load";
  }
  if (!value["own_weight"]) {
    errors.own_weight = "please add own_weight";
  }
  if (!value["max_weight"]) {
    errors.max_weight = "please add max_weight";
  }
  if (!value["number_of_axis"]) {
    errors.number_of_axis = "please add max_weight";
  }
  if (!value["status"]) {
    errors.status = " add status";
  }
  return errors;
};

function TrailerUpdate() {
  const { id } = useParams();
  const navgate = useNavigate();

  const [data, setData] = useState([]);
  const [formErrors, setformErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    let inputs = {
      ...data,
      [name]: value,
    };
    setformErrors(Validater(inputs));
  };
  useEffect(() => {
    getTrailersByid(id).then((res) => setData(res.data));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt();
    if (
      data.trailer_name !== "" &&
      data.trailer_type !== "" &&
      data.plate_number !== "" &&
      data.trailer_length !== "" &&
      data.trailer_width !== "" &&
      data.trailer_height !== "" &&
      data.working_load !== "" &&
      data.own_weight !== "" &&
      data.max_weight !== "" &&
      data.number_of_axis !== "" &&
      data.status !== ""
    ) {
      data["token_data"] = JSON.parse(token);
      updateTrailers(id, data).then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          toastSuccess("Successfully updated trailers");
          navgate("../trailers");
        }
      });
    } else {
      setformErrors(Validater(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Update Trailer" />
      <Card className="mb-3 border-0 shadow-sm rounded-2">
        <Card.Body>
          <div className="form-wrapper">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-600">Trailer</Form.Label>
                    <Form.Control
                      type="text"
                      name="trailer_name"
                      placeholder="Enter Trailer Name"
                      onChange={handleChange}
                      value={data.trailer_name}
                      className="border-2"
                      isInvalid={formErrors.trailer_name}
                    />
                    {formErrors.trailer_name && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.trailer_name}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-600"> Trailer Type</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="trailer_type"
                      onChange={handleChange}
                      value={data.trailer_type}
                      isInvalid={formErrors.trailer_type}
                    >
                      <option value="">Select</option>
                      <option value="S">Standard</option>
                      <option value="M">Mega</option>
                      <option value="V">Vario</option>
                    </Form.Select>
                    {formErrors.trailer_type && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.trailer_type}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3  mt-3">
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className="fw-600">Trailer Length</Form.Label>
                    <Form.Control
                      type="text"
                      name="trailer_length"
                      placeholder="40.6m"
                      onChange={handleChange}
                      value={data.trailer_length}
                      className="border-2 h-50"
                      isInvalid={formErrors.trailer_length}
                    />
                    {formErrors.trailer_length && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.trailer_length}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>{" "}
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className="fw-600">Trailer height</Form.Label>
                    <Form.Control
                      type="text"
                      name="trailer_height"
                      placeholder="40.5m"
                      onChange={handleChange}
                      value={data.trailer_height}
                      className="border-2 h-50"
                      isInvalid={formErrors.trailer_height}
                    />
                    {formErrors.trailer_height && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.trailer_height}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className="fw-600">Trailer Width</Form.Label>
                    <Form.Control
                      type="text"
                      name="trailer_width"
                      placeholder="40.6lbs"
                      onChange={handleChange}
                      value={data.trailer_width}
                      className="border-2 h-50"
                      isInvalid={formErrors.trailer_width}
                    />
                    {formErrors.trailer_width && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.trailer_width}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>{" "}
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className="fw-600">Trailer height</Form.Label>
                    <Form.Control
                      type="text"
                      name="trailer_height"
                      placeholder="40.5m"
                      onChange={handleChange}
                      value={data.trailer_height}
                      className="border-2 h-50"
                      isInvalid={formErrors.trailer_height}
                    />
                    {formErrors.trailer_height && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.trailer_height}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3  mt-3">
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className="fw-600">Working Load</Form.Label>
                    <Form.Control
                      type="text"
                      name="working_load"
                      placeholder="40.6lbs"
                      onChange={handleChange}
                      value={data.working_load}
                      className="border-2 h-50"
                      isInvalid={formErrors.working_load}
                    />
                    {formErrors.working_load && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.working_load}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={3}>
                  <Form.Group>
                    <Form.Label className="fw-600">Owm Weight</Form.Label>
                    <Form.Control
                      type="text"
                      name="own_weight"
                      placeholder="40.6lbs"
                      onChange={handleChange}
                      value={data.own_weight}
                      className="border-2 h-50"
                      isInvalid={formErrors.own_weight}
                    />
                    {formErrors.own_weight && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.own_weight}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-600">No Of Axis</Form.Label>
                    <Form.Control
                      type="text"
                      name="number_of_axis"
                      placeholder="2"
                      onChange={handleChange}
                      value={data.number_of_axis}
                      className="border-2 h-50"
                      isInvalid={formErrors.number_of_axis}
                    />
                    {formErrors.number_of_axis && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.number_of_axis}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3  mt-3">
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-600">
                      Trailer Platenumber
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="plate_number"
                      placeholder="Platenumber"
                      onChange={handleChange}
                      value={data.plate_number}
                      className="border-2 h-50"
                      isInvalid={formErrors.plate_number}
                    />
                    {formErrors.plate_number && (
                      <Form.Control.Feedback type="invalid">
                        {formErrors.plate_number}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3  mt-3">
                <Col lg={6}>
                  <Form.Group>
                    <Form.Label className="fw-600"> Status</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="status"
                      onChange={handleChange}
                      value={data.status}
                      isInvalid={formErrors.status}
                    >
                      <option value="">Select the status</option>
                      <option value="A">Available</option>
                      <option value="U">Unavilable</option>
                      <option value="H">Holiday</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5 mt-4"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Update Trailer
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default TrailerUpdate;
