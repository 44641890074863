import jwtDecode from "jwt-decode";
import { APIURL } from "../../constants/constants";
import http from "./ServerApi";

export const authlogin = async (email, password) => {
  return await http.post(APIURL + "/token_login/", { email, password });
};
export const getUserDetails = async (id) => {
  return await http.get(APIURL + `/user/${id}/`);
};

export const getCurrentUser = ()=>{
  try {
    const jwt = localStorage.getItem('token');
    const user = jwtDecode(jwt);
    return user;
  } catch (error) {
    return null;
  }
}

export const setToken = (token)=>{
  localStorage.setItem('token',JSON.stringify(token))
}

export const removeToken = ()=>{
  localStorage.removeItem('token')
}

export const getjwt = ()=>{
  return localStorage.getItem('token')
}