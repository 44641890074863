import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Table from "../Components/Tables";

import CardHeader from "../Components/CardHeader";
import { faPen, faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { deleteUserType, getUserType } from "../services/usertypeApi";
import { toastError, toastSuccess } from "../Components/ToastNotification";


const headerButtons = [
  {
    buttonTitle: "Add Usertype",
    buttonUrl: "/dashboard/add/usertype",
    icon: faUserPlus,
  },
];

function Usertypes() {
  const [data, setData] = useState([]);

  const retrieveUserType = () => {
    getUserType().then((res) => setData(res.data));
  };

  useEffect(() => {
    retrieveUserType();
  }, []);

  const deleteHandle = (row) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteUserType(row.id)
              .then((res) => {
                toastSuccess(`user_type ${row.user_type} deleted.`);
                retrieveUserType();
              })
              .catch((err) =>
                toastError(
                  `Failed to delete userType ${row.user_type}: ${err.message}`
                )
              );
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const columns = [
    {
      name: "serialNo",
      selector: (row) => data.indexOf(row)+1,
      sortable: true,
    },
    {
      name: "usertype",
      selector: (row) => row.user_type,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Link
            className="btn btn-info mr-3 br-50"
            to={`/dashboard/update/usertype/${row.id}`}
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
          <button
            className="btn btn-danger mr-3 br-50"
            onClick={() => deleteHandle(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="USER TYPES" />

      <Card className="mb-3 border-0 shadow-sm p-2 rounded-2">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Usertypes;
