import {
  faTicketSimple,
  faTrash,
  faPen
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { Link } from "react-router-dom";

import CardHeader from "./../Components/CardHeader";
import Table from "./../Components/Tables";
import { useState,useEffect} from "react";
import { deleteTicketType, getTicketTypes } from "../services/ticketType";
import { toastSuccess,toastError } from "../Components/ToastNotification";

const headerButtons = [
  {
    buttonTitle: "Add TicketType",
    buttonUrl: "/dashboard/add/tickettypes",
    icon: faTicketSimple,
  }
];

const TicketType = () => {
  const [data,setData] = useState([])
  const retrieveTicketType = () => {
    getTicketTypes().then((res) => setData(res.data));
  };

  const deleteHandle = (row) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteTicketType(row.id).then(res=>
              {
                toastSuccess(`ticketType ${row.ticket_type} deleted.`);
                retrieveTicketType();
              }
            ).catch(err=>
              toastError(
                `Failed to delete ticketType ${row.ticket_type}: ${err.message}`
              )
              )
          }
        },
        {
          label: "No",
        },
      ],
    });
  };
  

  useEffect(() => {
    retrieveTicketType();
  }, [])

  const columns = [
    {
      name: "serialNo",
      selector: (row) =>data.indexOf(row)+1,
      sortable: true,
    },
    {
      name: "TicketType",
      selector: (row) => row.ticket_type,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <Link
            className="btn btn-info mr-3 br-50"
            to={`/dashboard/update/tickettypes/${row.id}`}
          >
            <FontAwesomeIcon icon={faPen} />
          </Link>
          <button className="btn btn-danger mr-3 br-50" onClick={()=>deleteHandle(row)}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </>
      ),
    },
  ];


  return (
      <div className="container-fluid">
        <CardHeader headerItems={headerButtons} title="TICKET TYPES" />

        <Card className="mb-3 border-0 shadow-sm p-2 rounded-2">
          <Card.Body>
            <div className="row">
              <Table columns={columns} row={data} />
            </div>
          </Card.Body>
        </Card>
      </div>
  );
};

export default TicketType;
