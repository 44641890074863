
import { Navigate, Outlet } from 'react-router-dom'
import { getCurrentUser } from '../services/authServices'

export const ProtectedRoutes = ({children}) => {
    const auth = getCurrentUser()
    if(auth){
        return <Outlet/>
    }
    return <Navigate to="/"/>

}
