import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { toastError, toastSuccess } from "../../Components/ToastNotification";
import "./form.css";
import CardHeader from "../../Components/CardHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { getDriver, getManager } from "../../services/users";
import { getTicketTypes } from "./../../services/ticketType";
import { addTicket } from "../../services/tickets";
import { useNavigate } from "react-router-dom";
import { getjwt } from "../../services/authServices";

const headerButtons = [
  {
    buttonTitle: "Back to tickets",
    buttonUrl: "../tickets",
    icon: faArrowLeft,
  },
];

const ticketValidator = (value) => {
  let errors = {};
  if (!value["title"]) {
    errors.title = "Ticket Name is required";
  }
  if (!value["ticket_status"]) {
    errors.ticket_status = "Ticket Status is required";
  }
  if (!value["driver"]) {
    errors.driver = "driver is required";
  }
  if (!value["manager"]) {
    errors.manager = "manager is required";
  }
  if (!value["ticket_type"]) {
    errors.ticket_type = "Ticket Type is required";
  }
  if (!value["note"]) {
    errors.note = "Note is required";
  }
  return errors;
};

const INITIAL_DATA = {
  title: "",
  ticket_status: "",
  driver: "",
  manager: "",
  ticket_type: "",
  note: "",
};

const TicketAddForm = () => {
  const navgate = useNavigate();
  const [data, setData] = useState(INITIAL_DATA);
  const [formErrors, setFormErrors] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [manager, setManager] = useState([]);
  const [ticketType, setTicketType] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const inputs = {
      ...data,
      [name]: value,
    };
    setData({ ...data, [name]: value });
    setFormErrors(ticketValidator(inputs));
  };

  useEffect(() => {
    getDriver().then((res) => setDrivers(res.data));
    getManager().then((res) => setManager(res.data));
    getTicketTypes().then((res) => setTicketType(res.data));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = getjwt()
    if (
      data.title !== "" &&
      data.ticket_status !== "" &&
      data.driver !== "" &&
      data.manager !== "" &&
      data.ticket_type !== "" &&
      data.note !== ""
    ) {
      data['token_data'] = JSON.parse(token)
      addTicket(data).then((res) => {
        toastSuccess("succesfully created Ticket");
        navgate("../tickets");
      }).catch(res=>toastError(res.data));
    } else {
      setFormErrors(ticketValidator(data));
    }
  };

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Add Tickets" />

      <Card className="mb-3 border-0 shadow-sm rounded-5">
        <Card.Body>
          <div className="form-wrapper">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    placeholder="Enter the Ticket Name"
                    onChange={handleChange}
                    value={data.title}
                    className="border-2"
                    isInvalid={formErrors.title}
                  />
                  {formErrors.title && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.title}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Ticket Status</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="ticket_status"
                    onChange={handleChange}
                    value={data.ticket_status}
                    isInvalid={formErrors.ticket_status}
                  >
                    <option value="">select status</option>
                    <option value="open">open</option>
                    <option value="pending">pending</option>
                    <option
                      value="closed
                      "
                    >
                      closed
                    </option>
                  </Form.Select>
                  {formErrors.ticket_status && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.ticket_status}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Driver</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="driver"
                    onChange={handleChange}
                    value={data.driver}
                    isInvalid={formErrors.driver}
                  >
                    <option>select </option>
                    {drivers.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.user.first_name + " " + item.user.last_name}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.driver && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.driver}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Manager</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="manager"
                    onChange={handleChange}
                    value={data.manager}
                    isInvalid={formErrors.manager}
                  >
                    <option>select </option>
                    {manager.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.first_name + " " + item.last_name}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.manager && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.manager}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label className="fw-600">Ticket Type</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="ticket_type"
                    onChange={handleChange}
                    value={data.ticket_type}
                    isInvalid={formErrors.ticket_type}
                  >
                    <option>select </option>
                    {ticketType.map((item, idx) => (
                      <option key={idx} value={item.id}>
                        {item.ticket_type}
                      </option>
                    ))}
                  </Form.Select>
                  {formErrors.ticket_type && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.ticket_type}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group className="mb-3" as={Col}>
                  <Form.Label className="fw-600">Note</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="note"
                    placeholder="Add Note for ticket "
                    onChange={handleChange}
                    value={data.note}
                    style={{ height: "100px" }}
                    isInvalid={formErrors.note}
                  />
                  {formErrors.note && (
                    <Form.Control.Feedback type="invalid">
                      {formErrors.note}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Row>
              <hr></hr>
              <Button
                variant="primary"
                type="submit"
                className="shadow rounded-5"
              >
                <FontAwesomeIcon icon={faCircleCheck} /> Create Ticket
              </Button>
            </Form>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TicketAddForm;
