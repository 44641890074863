import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import TableActionButton from "../Components/TableActionButton";
import Table from "../Components/Tables";
import CardHeader from "./../Components/CardHeader";
import "./tickects.css";
import { getByTicketStatus } from "../services/tickets";
import { deleteDriver } from "../services/users";

const headerButtons = [];

// const conditionalRowStyles = [
//   {
//     when: (row) => row.status.toLowerCase() === "pending",
//     style: {
//       backgroundColor: "rgba(236, 161, 56, 0.9)",
//       color: "black",
//       "&:hover": {
//         cursor: "pointer",
//       },
//     },
//   },
// ];

const TicketPending = () => {
  const [data, setData] = useState([]);

  const refreshTicket = () => {
    getByTicketStatus("pending").then((res) => setData(res.data));
  };
  useEffect(() => {
    refreshTicket();
  }, []);

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Manager",
      selector: (row) => row.manager.first_name + " " + row.manager.last_name,
      sortable: true,
    },
    {
      name: "Driver",
      selector: (row) =>
        row.driver.user.first_name + " " + row.driver.user.last_name,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.ticket_type.ticket_type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.ticket_status,
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => row.ticket_status.toLowerCase() === "pending",
          style: {
            backgroundColor: "rgba(236, 161, 56, 0.9)",
            color: "black",
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ],
    },

    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/tickets/${row.id}`}
          UpdatePath={`/dashboard/update/tickets/${row.id}`}
          deleteId={row.id}
          refreshdata={refreshTicket}
          deleteFun={deleteDriver}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Pending Tickets" />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default TicketPending;
