import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Table from "../../Components/Tables";
import CardHeader from "./../../Components/CardHeader";
import { faGroupArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import TableActionButton from "../../Components/TableActionButton";
import {
  deleteDriverGroups,
  getDriverGroups,
} from "../../services/DriverGroups";

const headerButtons = [
  {
    buttonTitle: "Add Group",
    buttonUrl: "/dashboard/add/driversgroup",
    icon: faGroupArrowsRotate,
  },
];

const DriverGroup = () => {
  const [data, setData] = useState([]);

  const driverGroupList = () => {
    getDriverGroups().then((res) => setData(res.data));
  };

  useEffect(() => {
    driverGroupList();
  }, []);

  const columns = [
    {
      name: "No.",
      selector: (row) => data.indexOf(row) + 1,
      sortable: true,
    },
    {
      name: "Group name",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Drivers Count",
      selector: (row) => row.drivers.length,
    },
    {
      name: "",
      cell: (row) => (
        <TableActionButton
          detailPath={`/dashboard/detail/driversgroup/${row.id}`}
          UpdatePath={`/dashboard/update/driversgroup/${row.id}`}
          deleteId={row.id}
          deleteFun={deleteDriverGroups}
          refreshdata={driverGroupList}
        />
      ),
    },
  ];

  return (
    <div className="container-fluid">
      <CardHeader headerItems={headerButtons} title="Drivers Group" />
      <Card className="mb-3 border-0 shadow-sm p-2 rounded-5">
        <Card.Body>
          <div className="row">
            <Table columns={columns} row={data} />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DriverGroup;
